var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',[_vm._t("header"),_vm._l((_vm.form),function(part,index){return _c('div',{key:index,staticClass:"mb-4"},[_c('h5',[_vm._v(_vm._s(part.title))]),(part.title == `Category's specificities`)?_c('CategorySwitch',{attrs:{"story":_vm.story},on:{"updateCell":_vm.updateCell}}):_vm._e(),(part.title == `Longlist Review`)?_c('div',{staticClass:"mb-2"},[_vm._t("llr")],2):_vm._e(),_vm._l((part.fields),function(field,index){return _c('div',{key:index},[_c('mdb-row',{staticClass:"d-flex align-self-center"},[(
            _vm.formConf[field.field] ? !_vm.formConf[field.field].hideLabel : true
          )?_c('mdb-col',{attrs:{"sm":"12","md":_vm.formConf[field.field] &&
            _vm.formConf[field.field].col &&
            _vm.formConf[field.field].col.label
              ? _vm.formConf[field.field].col.label
              : _vm.formConf['default'].col.label}},[_c('label',[_vm._v(_vm._s(field.label))])]):_vm._e(),_c('mdb-col',{staticClass:"d-flex justify-content-end",attrs:{"md":_vm.formConf[field.field]
              ? _vm.formConf[field.field].col.field
              : _vm.formConf['default'].col.field,"sm":"10"}},[(field.fieldOptions)?_c(field.field,{tag:"component",attrs:{"initVal":_vm.story[field.propertyName],"propertyName":field.propertyName,"label":field.label,"options":field.fieldOptions},on:{"change":_vm.updateCell},model:{value:(_vm.story[field.propertyName]),callback:function ($$v) {_vm.$set(_vm.story, field.propertyName, $$v)},expression:"story[field.propertyName]"}}):_c(field.field,{tag:"component",attrs:{"initVal":_vm.story[field.propertyName],"propertyName":field.propertyName,"label":field.label},on:{"change":_vm.updateCell},model:{value:(_vm.story[field.propertyName]),callback:function ($$v) {_vm.$set(_vm.story, field.propertyName, $$v)},expression:"story[field.propertyName]"}})],1)],1),(_vm.formConf[field.field] ? !_vm.formConf[field.field].hideLabel : true)?_c('hr'):_vm._e()],1)})],2)}),(_vm.isFull())?_c('div',{staticClass:"text-center py-4 mt-3"},[_c('mdb-btn',{on:{"click":function($event){return _vm.markAsDone()}}},[_vm._v("Done !")])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }