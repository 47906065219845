
<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardFooter,
  mdbCardBody,
  mdbCardHeader,
  mdbInput,
  mdbBtn,
  mdbIcon,
  mdbBadge,
  mdbListGroup,
  mdbListGroupItem,
} from "mdbvue";
import BookmarkButton from "./BookmarkButton.vue";
import StoryService from "../services/story.service";
import KeywordsService from "../services/keywords.service";
import { userService } from "../services/user.service";
import { actitiviesService } from "../services/activities.service";
import { errorService } from "../services/error.service";
import addActitivites from "./addActivities";

import Chart from "./chart.js";
import _ from "underscore";
import { langConf } from "../conf/language.conf.js";
import StoryFlag from "./storyFlag.vue";
import moment from "moment";
import { bookmarkService } from "../services/bookmarks.service";
import LoadingBar from "./LoadingBar.vue";
export default {
  name: "Story",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardFooter,
    mdbCardHeader,
    mdbCardBody,
    mdbIcon,
    mdbBadge,
    mdbInput,
    mdbBtn,
    Chart,
    mdbListGroup,
    mdbListGroupItem,
    StoryFlag,
    BookmarkButton,
    addActitivites,
    LoadingBar,
  },
  data() {
    return {
      error: false,
      story: false,
      displayAccordion: null,
      filter: "",
      markCss: "",
      wordToSearch: "",
      datacollections: {
        labels: [],
        wordcount: {
          label: "word's count",
          data: [],
          backgroundColor: "#e0e0e0",
        },
      },
      datacollection: null,
      displayWordCount: true,
      state: "",
      selectedLang: null,
      keywords: [],
      role: [],
      otherStories: null,
      confirm: false,
      user: null,
      activitiesItem: [],
      writer: null,
      loadingTime: 0,
    };
  },
  created() {
    this.user = userService.getUser();
    this.role = this.user.role;
    this.loadData();
  },
  filters: {
    listToString(string) {
      return string.toString().replace(",", ", ");
    },
    formatDate(date) {
      return moment(date).format("MM-DD-YYYY");
    },
  },
  methods: {
    getOtherStories() {
      userService.getUserStories(this.story.user.name).then((data) => {
        this.otherStories = data.stories;
      });
    },
    compareDate(dateA, dateB) {
      return moment(dateA) > moment(dateB);
    },
    getStoryinfos: async (c) => {
      c.state = "Give me all chapters you have Wattpad !";
      c.loading = true;
      var tmpStory = await StoryService.getStory(c.$route.params.id).catch(
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error_code == 1017
          ) {
            actitiviesService
              .findOne({ storyId: c.$route.params.id }, { date: -1 })
              .then((response) => {
                if (response.activity != "already-removed") {
                  actitiviesService.update(
                    { storyId: c.$route.params.id },
                    { expired: c }
                  );
                  actitiviesService.add({
                    storyId: c.$route.params.id,
                    activity: "already-removed",
                  });
                }
                c.removeBookMark(c.$route.params.id);
              });
          }
          c.error = error.message;
          throw new Error(error);
        }
      );

      c.selectedLang = _.findWhere(langConf, { id: tmpStory.language.id });
      c.loadingTime = tmpStory.parts.length * 600;
      var story = await StoryService.getFullStory(c.$route.params.id);
      if (story.outOfLang) {
        c.state = `${story.language.name}  is not available on the tool`;
      } else {
        for (let p in story.parts) {
          let part = story.parts[p];
          c.datacollections.labels.push("part." + (parseInt(p) + 1));
          c.datacollections.wordcount.data.push(part.wordCount);
          for (var w in story.warning) {
            let warn = story.warning[w];
            let keywordsCount = _.values(part.keywords[warn].words).reduce(
              (a, b) => a + b,
              0
            );
            if (!c.datacollections[warn])
              c.datacollections[warn] = {
                label: warn + " keywords's count",
                data: [],
                backgroundColor: KeywordsService.conf[warn].hexa,
              };
            c.datacollections[warn].data.push(keywordsCount);
          }
        }
      }
      c.story = story;
      c.fillData();
    },
    loadData() {
      this.getStoryinfos(this).catch((e) => {
        errorService.add(
          e,
          "Story.Vue 185",
          this.selectedLang ? this.selectedLang : "unknow",
          "story  " + this.$route.params.id
        );
        throw e;
      });
    },
    removeBookMark(storyId) {
      bookmarkService
        .list({ bookmark: "story/" + storyId, user: this.user.username })
        .then((response) => {
          for (let r in response) {
            bookmarkService.deleteBookmarks(response[r]._id.$oid);
          }
        });
      let actualbmIndex = _.findLastIndex(this.$root.bookmarks["story"], {
        user: userService.getUser().username,
        type: "story",
        bookmark: "story/" + storyId,
      });
      if (actualbmIndex >= 0) {
        this.$root.bookmarks["story"].splice(actualbmIndex, 1);
        userService.updateBookMarks(this.$root.bookmarks);
      }
    },
    accordion(partId) {
      this.displayAccordion = this.displayAccordion == partId ? null : partId;
    },
    lookingForWord() {
      if (this.wordToSearch == "") return;
      for (var p in this.story.parts) {
        this.filter = "customSearch";
        this.story.parts[p].content = KeywordsService.keyWordsHiglight(
          this.story.parts[p].content,
          [this.wordToSearch],
          "customSearch"
        );
        this.story.parts[p].findSearchedWord =
          this.story.parts[p].content.indexOf(this.wordToSearch) > 0;
      }
    },
    selectFilter(filter) {
      this.filter = filter == this.filter ? null : filter;
      this.fillData(filter);
    },
    toggleWordCount() {
      this.displayWordCount = !this.displayWordCount;
      this.fillData(this.filter);
    },
    roleIs(role) {
      return this.role.indexOf(role) > -1;
    },
    checkFollowersCount() {
      if (this.story.user.numFollowers > 10000 && this.roleIs("divers"))
        alert(
          "Be careful, this user has more than 10000 followers. Please inform the T&S before do anything."
        );
    },
    activityCallBack(newActivity) {
      if (newActivity.activity.indexOf("delete") > -1) {
        this.writer = this.story.user.name;
        this.story = null;
        this.confirm = true;
      } else {
        if (!this.story.activities) this.story.activities = [];
        newActivity = Object.assign({}, newActivity, {
          date: moment(new Date(newActivity.date)).format("MM-DD-YYYY HH:mm"),
          content:
            newActivity.user +
            " - " +
            newActivity.activity.split("-").join(" "),
          icon: actitiviesService.actionsList[newActivity.activity]
            ? actitiviesService.actionsList[newActivity.activity].icon
            : actitiviesService.default.icon,
          color: actitiviesService.actionsList[newActivity.activity]
            ? actitiviesService.actionsList[newActivity.activity].color
            : actitiviesService.default.color,
        });
        this.story.activities.unshift(newActivity);
        if (newActivity.activity.indexOf("mark"))
          this.story.isClean = newActivity.activity.indexOf("mark-as-unclean")
            ? false
            : true;
      }
    },
    fillData(name) {
      this.datacollection = {
        labels: this.datacollections.labels,
        datasets: [],
      };
      if (this.displayWordCount)
        this.datacollection.datasets.push(this.datacollections.wordcount);
      if (name && name == this.filter)
        this.datacollection.datasets.push(this.datacollections[name]);
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    getColor(filter) {
      if (filter == "wordcount")
        return this.displayWordCount ? "grey" : "grey lighten-2";
      if (KeywordsService.conf[filter])
        return this.filter == filter
          ? KeywordsService.conf[filter].color
          : KeywordsService.conf[filter].color + " lighten-2";
      return "primary";
    },
    chartHeight() {
      return this.story.parts.length * 5 + 50;
    },
    filteredKeywords(keywords) {
      if (keywords && this.filter && keywords[this.filter]) {
        let keys = _.keys(keywords[this.filter].words);
        if (keys.length > 0)
          return "(" + keys.toString().replace(",", ", ") + ")";
      }
    },
    filteredContent(index) {
      return this.story.parts[index].content;
    },
  },
};
</script>
<template>
  <mdb-container v-if="story">
    <div v-if="story.isClean" class="alert alert-success" role="alert">
      <strong>Clean story </strong>According to {{ story.markeAcapBy }} this
      fiction is clean ! If it's not
      <strong> <a v-on:click="toggleClean()">click here</a> </strong>
    </div>
    <div
      v-if="!story.outOfLang && !selectedLang"
      class="alert alert-success"
      role="alert"
    >
      <strong>Clean story </strong>According to {{ story.markeAcapBy }} this
      fiction is clean ! If it's not
      <strong> <a v-on:click="toggleClean()">click here</a> </strong>
    </div>
    <div
      v-if="!story.outOfLang && story.warning && story.warning.length > 0"
      class="alert alert-warning"
      role="alert"
    >
      <strong>Warning : </strong>Accoding to his tags, title or description,
      this fiction could be
      <strong> {{ story.warning | listToString }} </strong>
    </div>
    <mdb-card class="mb-5">
      <mdb-card-body>
        <mdb-row class="mb-3">
          <mdb-col md="4">
            <mdb-row class="d-flex justify-content-center">
              <mdb-col class="text-center">
                <add-actitivites
                  v-if="!story.outOfLang"
                  @activityCallBack="activityCallBack"
                  :ambassador="user.username"
                  :story="story"
                  page="story"
                  :userRole="role"
                  :specificActions="[
                    'report-cover-to-wattpad',
                    'report-cover-to-diver',
                  ]"
                />
                <img class="img-fluid" :src="story.cover" :alt="story.title" />
              </mdb-col>
            </mdb-row>
            <mdb-row class="mt-2">
              <mdb-col>
                <p class="text-center">
                  <mdb-icon
                    size="1x"
                    icon="eye"
                    class="pr-3"
                    aria-hidden="true"
                  />{{ story.readCount }} -
                  <mdb-icon
                    size="1x"
                    icon="chat"
                    class="pr-3"
                    aria-hidden="true"
                  />{{ story.commentCount }} -
                  <mdb-icon
                    size="1x"
                    icon="star"
                    class="pr-3"
                    aria-hidden="true"
                  />{{ story.voteCount }}
                </p>
                <bookmark-button
                  class="text-center"
                  :bookmark="'story/' + story.id"
                  type="story"
                />
              </mdb-col>
            </mdb-row>
          </mdb-col>
          <mdb-col md="7" class="text-md-left ml-3 mt-3">
            <a href="#!" class="green-text">
              <h6 class="h6 pb-1">
                <a
                  v-on:click="checkFollowersCount()"
                  :href="'https://www.wattpad.com/story/' + story.id"
                  class="text-reset"
                  ><mdb-icon icon="eye" class="pr-1" /> See on Wattpad</a
                >
              </h6>
            </a>
            <h4 class="h4 mb-2">{{ story.title }}</h4>
            <story-flag :story="story"></story-flag>
            <p class="font-weight-normal">
              <strong>Created at </strong>{{ story.createDate | formatDate }},
              <strong>Updated at </strong>{{ story.modifyDate | formatDate }}
            </p>

            <p class="font-weight-normal">
              par
              <a
                v-on:click="checkFollowersCount()"
                :href="'https://www.wattpad.com/user/' + story.user.name"
                target="_blank"
                class="text-reset"
                ><strong>{{ story.user.name }}</strong></a
              >
              &nbsp; &nbsp;
              <mdb-badge v-if="story.user.numFollowers >= 10000" color="danger">
                {{ story.user.numFollowers }} followers</mdb-badge
              >
              <mdb-badge v-else color="grey">
                {{ story.user.numFollowers }} followers</mdb-badge
              >
            </p>
            <p class="font-weight-normal mt-2 mb-2">{{ story.description }}</p>
            <p class="font-weight-normal">
              <strong>Tags: </strong>{{ story.tags | listToString }}
            </p>
          </mdb-col>
        </mdb-row>
      </mdb-card-body>
      <mdb-card-footer v-if="!story.outOfLang">
        <p class="float-right">
          <add-actitivites
            @activityCallBack="activityCallBack"
            :story="story"
            :ambassador="user.username"
            page="story"
            :complete="story.completed"
            :userRole="role"
            :specificActions="[
              'mark-as-clean-as-possible',
              'mark-as-clean',
              'mark-as-unclean',
              'ts-reported',
            ]"
          />
        </p>

        <p></p>
        <p>
          <add-actitivites
            @activityCallBack="activityCallBack"
            :story="story"
            :ambassador="user.username"
            page="story"
            :userRole="role"
            :without="[
              'report-cover-to-wattpad',
              'report-cover-to-diver',
              'mark-as-clean-as-possible',
              'mark-as-clean',
              'mark-as-unclean',
              'ts-reported',
            ]"
          />
        </p>
      </mdb-card-footer>
    </mdb-card>
    <mdb-row>
      <mdb-col col="sm">
        <mdb-card class="mb-5">
          <mdb-card-header>
            <a
              class="float-right"
              :href="'/user/' + story.user.name"
              target="_blank"
            >
              Writer's page
            </a>
            <h4>
              <em
                ><a
                  :href="'https://www.wattpad.com/user/' + story.user.name"
                  target="_blank"
                  class="text-reset"
                  >{{ story.user.name }}</a
                ></em
              >'s other stories
            </h4>
          </mdb-card-header>
          <mdb-card-body v-if="otherStories == null">
            <a v-on:click="getOtherStories()">Clic to see writer's stories</a>
          </mdb-card-body>
          <mdb-card-body v-else>
            <mdb-list-group>
              <mdb-list-group-item
                v-for="story in otherStories"
                v-bind:key="story.id"
              >
                <a
                  :href="'https://www.wattpad.com/story/' + story.id"
                  target="_blank"
                  v-on:click="checkFollowersCount()"
                >
                  {{ story.title }}</a
                >

                <a :href="'/story/' + story.id" taget="_blank"
                  ><mdb-badge color="green" pill>Analyze !</mdb-badge></a
                >
              </mdb-list-group-item>
            </mdb-list-group>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col col="sm" v-if="story.activities.length > 0">
        <mdb-card class="mb-5">
          <mdb-card-header>
            <h4>Story's logs</h4>
          </mdb-card-header>
          <mdb-card-body
            v-if="otherStories == null"
            style="max-height: 300px; overflow: auto"
          >
            <light-timeline :items="story.activities">
              <template slot="tag" slot-scope="{ item }">
                {{ item.date | formatDate }}
              </template>
              <template slot="symbol" slot-scope="{ item }">
                <i
                  :class="
                    'fas fa-lg fa-' + item.icon + ' ' + item.color + '-text'
                  "
                ></i>
              </template>
              <template slot="content" slot-scope="{ item }">
                {{ item.content }}
                <span
                  style="font-size: 0.8em"
                  v-if="item.details"
                  v-html="item.details"
                ></span>
              </template>
            </light-timeline>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
    <mdb-card class="mb-5" v-bind:class="'display-' + filter">
      <mdb-card-header>
        <div v-if="!story.outOfLang" class="float-right">
          Display :
          <mdb-badge
            tag="a"
            pill
            v-for="warn in story.warning"
            :color="getColor(warn)"
            v-bind:key="'filter-' + warn"
          >
            <span v-on:click="selectFilter(warn)">{{ warn }}</span></mdb-badge
          >
          <mdb-badge tag="a" pill :color="getColor('wordcount')"
            ><span v-on:click="toggleWordCount()">wordcount</span></mdb-badge
          >
        </div>
        <h4>
          <em>{{ story.title }}</em
          >'s chapters ( {{ story.wordsCount }} words)
        </h4>
      </mdb-card-header>
      <mdb-card-header v-if="!story.outOfLang">
        <label ariaDescribedBy="button-addon2"
          ><strong>Looking for specific word :</strong>
        </label>
        <mdb-input
          ariaDescribedBy="button-addon2"
          class="mt-0 mb-3"
          v-model="wordToSearch"
        >
          <mdb-btn
            group
            slot="append"
            id="button-addon2"
            v-on:click="lookingForWord()"
            color="secondary"
            size="md"
            >Search</mdb-btn
          >
        </mdb-input>
      </mdb-card-header>
      <mdb-card-body v-if="!story.outOfLang">
        <chart :chart-data="datacollection" :height="chartHeight()"></chart>
        <!--             <p><a href="#!" class="green-text"><mdb-icon icon="pie-chart"/>More stats</a></p> -->
      </mdb-card-body>
      <div
        class="accordion"
        v-for="(part, index) in story.parts"
        v-bind:key="'part-' + part.id"
      >
        <mdb-card-header class="accordion-card-header">
          <div>
            <div class="float-right">
              <a
                v-on:click="checkFollowersCount()"
                :href="'https://www.wattpad.com/' + part.id"
                target="_blank"
                >see on Wattpad</a
              >
            </div>
            <a class="d-flex" v-on:click="accordion(part.id)"
              >{{ part.title }} {{ filteredKeywords(part.keywords) }}
              <span v-if="part.findSearchedWord"> - Word here ! -</span>
              <span v-if="displayWordCount">
                {{ " - " + part.wordCount }} words
              </span></a
            >
          </div>
        </mdb-card-header>
        <mdb-card-body
          v-if="displayAccordion == part.id"
          class="accordion-card-body"
          v-html="filteredContent(index)"
        >
        </mdb-card-body>
      </div>
    </mdb-card>
    <mdb-card>
      <mdb-card-header
        ><em>{{ story.title }}</em
        >'s images</mdb-card-header
      >
      <mdb-card-body>
        <mdb-row>
          <mdb-col
            sm="4"
            v-for="(img, index) in story.images"
            v-bind:key="'img' + index"
          >
            <a :href="img.chapter" target="_blank"
              ><img :src="img.img" class="img-fluid"
            /></a>
          </mdb-col>
        </mdb-row>
      </mdb-card-body>
    </mdb-card>
  </mdb-container>
  <mdb-container v-else-if="confirm">
    <div class="text-center">
      <p>
        Got it :) You can close this, or mabe take a look to the
        <a :href="'/user/' + writer">writer page</a>
      </p>
    </div>
  </mdb-container>
  <mdb-container v-else-if="error">
    <div class="text-center">
      <p>{{ error }}</p>
    </div>
  </mdb-container>
  <mdb-container v-else>
    <div class="text-center">
      <p>{{ state }}</p>
      <loading-bar v-if="loadingTime" :gap="600" :initalTime="loadingTime" />
    </div>
  </mdb-container>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}
.accordion-card-header {
  cursor: pointer;
}
.accordion-card-body img {
  max-width: 100%;
}
</style>
<style>
mark {
  background: none;
}
.display-porn mark.porn {
  background: #e91e63;
}
.display-copyright mark.copyright {
  background: #673ab7;
}
.display-self-harm mark.self-harm {
  background: #ff9800;
}
.display-customSearch mark.customSearch {
  background: rgb(6, 235, 196);
}
</style>
