import axios from 'axios'

const WattpadV5Service = {
    baseURL:'',
    init(baseURL) {
        this.baseURL = baseURL;
    },
    get(resource) {
        return axios.get(`${this.baseURL}${resource}`, {"headers":{"authorization": process.env.VUE_APP_WP_KEY}});
    },
    customRequest(data) {
        return axios(data)
    },
}

export default WattpadV5Service