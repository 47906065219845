import Vue from 'vue';
import Router from 'vue-router';

import { userService } from "../services/user.service"

import Documentation from '@/components/Documentation'
import Profile from '@/components/Profile'
import DiverHole from '@/components/DiverHole'
import Hotlist from '@/components/Hotlist'
import Story from '@/components/Story'
import WPUser from '@/components/WPUser'
import BadGateway from '@/components/BadGateway'
import PublicLayout from '@/layouts/PublicLayout'
import PrivateLayout from '@/layouts/PrivateLayout'
import Wattys from '@/components/Wattys/Wattys'

import Login from '@/components/Login'

Vue.use(Router);
const router = new Router({
  mode: 'history',
  routes: [
    {
    path: "/welcome",
    component: PublicLayout,
    name: "Authentication",
    children: [
        {
          path: "/login",
          name: "Login",
          component: Login,
          meta: {
            public: true, 
            onlyWhenLoggedOut: true
          }
        },
        {
          path: '/404',
          name: 'BadGateway',
          component: BadGateway
        },
        {
          path: '*',
          redirect: '/404'
        }
      ]
    },
    {
      path: "/",
      redirect: "/doc",
      props: { page: 3 },
      component: PrivateLayout,
      children: [
        {
          path: '/hotlist/:lang?/:keywords?',
          name: 'Hotlist moderation',
          component: Hotlist,
          props: { page: 1 }
        },
        {
          path: '/doc',
          name: 'Documentation',
          component: Documentation,
          props: { page: 3 },
          alias: '/'
        },
        {
          path: '/ambassador/:username',
          name: 'Ambassador',
          props: { page: 2 },
          component: Profile
        },
        {
          path: '/story/:id',
          name: 'Story Moderation',
          props: { page: 4 },
          component: Story
        },
        {
          path: '/user/:username',
          name: 'User Moderation',
          props: { page: 5 },
          component: WPUser
        },
        {
          path: '/diverhole/:lang?',
          name: 'Diver Hole',
          props: { page: 6 },
          component: DiverHole
        },
        {
          path: '/wattys',
          name: 'Wattys',
          props: { page: 7 },
          component: Wattys
        },
        
      ]
    }
  ]
});
router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = userService.isLogged();
  if (!isPublic && !loggedIn) {
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    });
  }
  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next({
      path:'/doc',
      propos: {page:1}
  })
  }
  next();
})

export default router

