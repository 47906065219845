const keywordsConf = {
  porn: {
    icon: "fire",
    color: "pink",
    hexa: "#e91e63"
  },
  "self-harm": {
    icon: "bell",
    color: "orange",
    hexa: "#ff9800"
  },
  copyright: {
    icon: "copyright",
    color: "deep-purple",
    hexa: "#673ab7"
  },
  "fan-fiction": {
    icon: "heart",
    color: "red",
    hexa: "#673ab7"
  },
  customSearch: {
    icon: "copyright",
    color: "deep-purple",
    hexa: "#eee"
  },
  undefined: {
    icon: "interrogation",
    color: "gray",
    hexa: "#eee"
  }
};
export { keywordsConf };
