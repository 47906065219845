<template>
  <div>
    <span v-bind:key="index" v-for="(activity, index) in activitiesButton">
      <a
        v-if="activity.formfileds"
        v-on:click="
          reasonModal = true;
          activeButton = activity;
        "
      >
        <actionButton :activity="activity" />
      </a>
      <a
        v-else-if="activity.id == 'mark-as-unclean'"
        v-on:click="addActivity(activity)"
      >
        <actionButton v-if="isClean()" :activity="activity" />
      </a>
      <a
        v-else-if="activity.id == 'mark-as-clean'"
        v-on:click="addActivity(activity)"
      >
        <actionButton v-if="complete && !isClean()" :activity="activity" />
      </a>
      <a
        v-else-if="activity.id == 'mark-as-clean-as-possible'"
        v-on:click="addActivity(activity)"
      >
        <actionButton v-if="!isClean() && !complete" :activity="activity" />
      </a>
      <a
        v-else-if="activity.id == 'report-CESM'"
        v-on:click="addActivity(activity)"
      >
        <actionButton :activity="activity" />
      </a>
      <a
        v-else
        v-on:click="addActivity(activity)"
        :href="formAdress(activity)"
        target="_blank"
      >
        <actionButton :activity="activity" />
      </a>
    </span>
    <mdb-modal
      v-if="activeButton"
      :show="reasonModal"
      @close="reasonModal = false"
    >
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100"
          >{{ activeButton.label }} report</mdb-modal-title
        >
      </mdb-modal-header>
      <mdb-modal-body class="mx-3 grey-text">
        <div
          v-bind:key="index"
          v-for="(field, index) in activeButton.formfileds"
        >
          <mdb-textarea
            v-model="field.value"
            :icon="field.icon"
            :label="field.label"
            :rows="10"
          />
          <p
            class="text-muted"
            style="font-size: 0.8em; margin-top: -15px"
            v-if="field.description"
          >
            {{ field.description }}
          </p>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <a
          :href="formAdress(activeButton)"
          target="_blank"
          @click="addActivity(activeButton)"
          type="button"
          class="btn btn-primary"
          color="unique"
          >Send <mdb-icon icon="paper-plane" class="ml-1"
        /></a>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>
<script>
import { actitiviesService } from "../services/activities.service";
import "@kouts/vue-modal/dist/vue-modal.css";
import actionButton from "./actionButton";
import _ from "underscore";
import {
  mdbTextarea,
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";

import { langConf } from "../conf/language.conf.js";
export default {
  props: {
    page: String,
    userRole: String,
    specificActions: Array,
    complete: Boolean,
    story: Object,
    ambassador: String,
    without: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  components: {
    actionButton,
    mdbTextarea,
    mdbIcon,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
  },
  created() {
    this.activitiesButton = [];
    if (this.specificActions) {
      for (let s in this.specificActions) {
        let action = _.findWhere(actitiviesService.actionsList, {
          id: this.specificActions[s],
        });
        if (action.allowedRoles.indexOf(this.userRole) > -1)
          this.activitiesButton.push(action);
      }
    } else {
      for (let a in actitiviesService.actionsList) {
        if (
          actitiviesService.actionsList[a].allowedRoles.indexOf(this.userRole) >
            -1 &&
          -1 &&
          this.without.indexOf(actitiviesService.actionsList[a].id) == -1 &&
          !actitiviesService.actionsList[a].hiddenActivity
        )
          this.activitiesButton.push(actitiviesService.actionsList[a]);
      }
    }
  },
  data() {
    return {
      activitiesButtons: [],
      reasonModal: false,
      activeButton: false,
      cleanMutation: null,
    };
  },
  methods: {
    isClean() {
      return this.cleanMutation != null
        ? this.cleanMutation
        : this.story.isClean;
    },
    addActivity(activity) {
      let newActivity = {
        wpuser: this.story.user.name,
        storyId: this.story.id,
        storyTitle: this.story.title,
        storylang: this.story.language.id,
        activity: activity.id,
        source: "app",
      };
      if (activity.id == "mark-as-clean-as-possible")
        newActivity.lastPublishedPart = this.story.lastPublishedPart;
      if (activity.id.indexOf("mark") > -1 || activity.id == "ts-reported")
        return actitiviesService.add(newActivity).then((newActivity) => {
          if (newActivity.activity.indexOf("mark") > -1) {
            this.cleanMutation =
              newActivity.activity.indexOf("mark-as-unclean") > -1
                ? false
                : true;
          }
          this.$emit("activityCallBack", newActivity);
        });
      if (activity.formfileds)
        newActivity.reasonToReport = _.pluck(activity.formfileds, "value").join(
          " | "
        );
      if (
        this.story.user.numFollowers > 10000 &&
        this.userRole.indexOf("diver") > -1
      )
        alert(
          "Be careful, this user has more than 10000 followers. Please inform the T&S before do anything."
        );
      let title =
        newActivity.activity == "report-CESM"
          ? `STOP READING!`
          : `Confirmation`;
      let message =
        newActivity.activity == "report-CESM"
          ? `Please escalate this ID [${this.story.id}] to a diver on the #potential-csem-assistance-requests channel, then confirm you did it or not.`
          : `Please confirm you actually take this action`;
      this.$confirm({
        title: title,
        message: message,
        button: {
          no: "I didn't",
          yes: "I did!",
        },
        callback: (confirm) => {
          if (confirm) {
            actitiviesService.add(newActivity).then((newActivity) => {
              this.$emit("activityCallBack", newActivity);
            });
          }
        },
      });
    },
    formAdress(activity) {
      let lang = _.findWhere(langConf, { id: this.story.language.id });
      if (activity.link) {
        let formAdress = activity.link;

        for (let b in activity.formfileds) {
          let btn = activity.formfileds[b];
          formAdress =
            formAdress + "&entry." + btn.extFormEntry + "=" + btn.value;
        }
        formAdress = formAdress
          .replace("{STORY_ID}", this.story.id)
          .replace("{USERNAME}", this.ambassador)
          .replace("{COVER}", this.story.cover)
          .replace("{LANG}", lang.title);
        return formAdress;
      }
    },
  },
};
</script>
