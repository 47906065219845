const langConf = [
  {
    title: "English",
    id: 1,
    code: "en",
    divId: "207702015",
    wattys:"1MMdGIDUi1E_EBBO61Z_Tprsb-q0shVSgjolvFZFQE8o"
  },
  {
    title: "French",
    id: 2,
    code: "fr",
    divId: "923523268",
    wattys:"1fjxqbujWHikQ5mU9noaKDdoqy8uNUp4TI-RmkCiUCS0",
  },
  {
    title: "Italian",
    id: 3,
    code: "it",
    divId: "1985657807"
  },
  {
    title: "Deutch",
    id: 4,
    code: "de",
    divId: "1602330013"
  },
  {
    title: "Spanish",
    id: 5,
    code: "es",
    divId: "857422987"
  },
  {
    title: "Portugais",
    id: 6,
    code: "pt",
    divId: "618718721"
  },
  {
    title: "Română",
    id: 15,
    code: "ro",
    divId: "419959487"
  },
  {
    title: "Arabic",
    id: 16,
    code: "ar",
    divId: "361090173"
  },
  {
    title: "Bahasa Melayu",
    id: 22,
    code: "my",
    divId: "999693253"
  },
  {
    title: "Tiếng Việt",
    id: 19,
    code: "vi",
    divId: "444344520"
  },
  {
    title: "Bahasa Indonesia",
    id: 20,
    code: "id",
    divId: "620355327"
  },
  {
    title: "Türkçe",
    id: 23,
    code: "tr",
    divId: "446794499"
  },
  {
    title: "Slovenčina",
    id: 44,
    code: "sk",
    divId: "518654749"
  },
  {
    title: "Hindi",
    id: 21,
    code: "hi",
    divId: "1841946943"
  },
  {
    title: "Russian",
    id: 7,
    code: "ru",
    divId: "1777900684"
  },
  {
    title: "Hungarian",
    id: 28,
    code: "hu",
    divId: "1518723966"
  },
  {
    title: "Bengali",
    id: 47,
    code: "bn",
    divId: "1554554592"
  },
  {
    title: "Hebrew",
    id: 17,
    code: "he",
    divId: "643973294"
  },
  {
    title: "Filipino",
    id: 18,
    code: "ph",
    divId: "1578283379"
  },
  {
    title: "Dutch",
    id: 13,
    code: "nl",
    divId: "1774263029"
  },
  {
    title: "Czech",
    id: 24,
    code: "cz",
    divId: "1487110737"
  },
  {
    title: "Polish",
    id: 14,
    code: "pl",
    divId: "1896414799"
  }
];
export { langConf };
