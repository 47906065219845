<script>
import WattysForm from "./form/WattysForm.vue";

import { mdbListGroup, mdbListGroupItem } from "mdbvue";
import { Tabs, Tab } from "vue-slim-tabs";
import LLStoryPart from "./LLStoryPart.vue";
export default {
  components: {
    Tabs,
    Tab,
    mdbListGroup,
    mdbListGroupItem,
    LLStoryPart,
    WattysForm,
  },
  props: {
    story: Object,
    sheetConfig: Object,
  },
  data() {
    return {
      parts: [],
      form: [],
    };
  },
  methods: {
    updateCell(value, propertyName) {
      this.story[propertyName] = value;
      this.$emit("updateCell", this.story.story_id, value, propertyName);
      if (propertyName == "Confirm Category") {
        this.form[1].fields = this.sheetConfig.columns.filter(
          (i) =>
            i.field &&
            i.category &&
            i.category == this.story["Confirm Category"]
        );
        //clear other category scores
        this.sheetConfig.columns
          .filter((i) => i.field == "Slider" && i.category)
          .map(async (i) => {
            this.story[i.propertyName] = 0;
            await this.updateCell(0, i.propertyName);
          });
      }
      if (
        this.sheetConfig.columns.find((i) => i.propertyName == propertyName)
          .field == "Slider"
      )
        this.story.score = this.sheetConfig.columns
          .filter((i) => i.field == "Slider")
          .map((i) => {
            if (this.story[i.propertyName] != "")
              return this.story[i.propertyName];
          })
          .filter((i) => i)
          .reduce((sum, x) => sum + x);
    },
    async resolveStory(value) {
      this.$emit("resolveStory", this.story.story_id, value);
    },
    async markAsDone() {
      this.$emit("markAsDone", this.story.story_id);
    },
  },
  async created() {
    let fields = this.sheetConfig.columns.filter((i) => i.field && !i.category);
    let last = fields.pop();
    this.form = [
      {
        title: "Main questions",
        fields: fields,
      },
      {
        title: "Category's specificities",
        fields: this.sheetConfig.columns.filter(
          (i) =>
            i.field &&
            i.category &&
            i.category == this.story["Confirm Category"]
        ),
      },
      {
        title: "Conclusion",
        fields: [last],
      },
    ];

    let wpParts = this.story.wpStory.parts.map((item, index) => {
      item.partNum = Number(index) + 1;
      return item;
    });
    this.partsCount = wpParts.length;
    let middle = Math.round(this.partsCount / 2);
    this.parts = {
      begin: wpParts.slice(0, 5),
      middle: wpParts.slice(middle - 3, middle + 3),
      end: wpParts.slice(this.partsCount - 5),
    };
  },
};
</script>

<template>
  <tabs>
    <tab title="1 - SKRIM READ">
      <p>
        Skrim read : read 2-3 chapters at the beginning of the story, 1-2 in the
        middle, and 1-2 at the end to gain a better understanding of the story.
      </p>

      <mdb-list-group flush v-for="(sequence, key) in parts" v-bind:key="key">
        <p class="text-uppercase my-2">{{ key }}</p>
        <mdb-list-group-item v-for="part in sequence" v-bind:key="part.id">
          <LLStoryPart :part="part"></LLStoryPart>
        </mdb-list-group-item>
      </mdb-list-group>
    </tab>
    <tab title="2 - JUDGE">
      <WattysForm
        :story="story"
        :sheetConfig="sheetConfig"
        :form="form"
        @updateCell="updateCell"
        @resolveStory="resolveStory"
        @markAsDone="markAsDone"
      >
        <template v-slot:header>
          <h4 class="h4 text-center py-4"></h4>
          <p>
            Score : {{ story.score }}<br />
            <small>
              Only stories with 17 points or more can be selected to the
              shortlist (no less than "Good" on each question)
            </small>
          </p>
        </template>
      </WattysForm>
    </tab>
  </tabs>
</template>

<style src="vue-slim-tabs/themes/default.css"></style>