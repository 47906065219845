<template>
        <progress-bar
        size="medium"
          :val="progress"
          :text="progress + '% '"
        ></progress-bar>
</template>

<script>

import ProgressBar from "vue-simple-progress";
export default {
      components: {
    ProgressBar,
  },
  data(){
    return {
        progress:0,
        countDown:0
    }
  },
        created(){
           this.countDownTimer();
        },
        props:{
            initalTime:Number,
            gap:Number
        },
        methods: {
            countDownTimer() {
                if(this.countDown < this.initalTime) {
                    setTimeout(() => {
                        this.countDown = this.countDown + this.gap;
                        this.progress = Math.round(this.countDown/this.initalTime*100)
                        this.countDownTimer()
                    }, this.gap)
                }
            }
        }
    }
</script>
