<template>
  <main>
      <mdb-container>
        <router-view></router-view>
      </mdb-container>
    </main>
</template>

<script>

  import {
    mdbContainer
  } from "mdbvue";

export default {
  name: 'PublicLayout',
  components: {
    mdbContainer
   },
  data () {
    return {
      msg: 'Welcome to Your MDB Vue.js App'
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}
</style>
