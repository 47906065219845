<script>
import WattyService from "@/services/wattys.service";
import {
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbIcon,
} from "mdbvue";

export default {
  components: {
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbIcon
},
  props: {
    story: Object,
  },
  data() {
    return {
      editAward: false,
      award_categories: WattyService.award_categories,
    };
  },
  methods: {
    setAwardCategory(value) {
      this.$emit("updateCell",value, "Confirm Category");
      this.editAward = false;
      this.story["Confirm Category"] =value;
    },
  },
};
</script>
<template>
<div>
<mdb-dropdown v-if="editAward">
          <mdb-dropdown-toggle
            color="primary"
            size="md"
            slot="toggle"
            class="z-depth-0"
          >
            <span>{{ story["award_category"] }}</span>
          </mdb-dropdown-toggle>
          <mdb-dropdown-menu class="displayoverflow">
            <mdb-dropdown-item
              @click="setAwardCategory(c)"
              v-for="c in award_categories"
              v-bind:key="c"
              >{{ c }}</mdb-dropdown-item
            >
          </mdb-dropdown-menu>
        </mdb-dropdown>
        <div v-else class="d-flex flex-row">
          <h6 class="h6 pb-1 green-text">
            <span
              v-bind:class="{
                'grey-text': story.award_category != story['Confirm Category'],
              }"
              >{{ story.award_category }}</span
            >

            <span v-if="story.award_category != story['Confirm Category']"
              >-> {{ story["Confirm Category"] }}</span
            >
          </h6>
          <a
            href="#!"
            class="mx-2 grey-text"
            role="button"
            @click="editAward = true"
          >
            <mdb-icon icon="pen" class="mr-1" />
          </a>
          <i> - subgenre : {{ story.subgenre }} </i>
        </div>
</div>

</template>
