import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbvue/lib/css/mdb.min.css";
import Vue from "vue";
import App from "./App";
import VueRouter from "vue-router";
import router from "./router/router.js";

import store from "./store";

import ApiService from "./services/api.service.js";
import WattpadV2Service from "./services/wattpadV2.service.js";
import WattpadV3Service from "./services/wattpadV3.service.js";
import WattpadV4Service from "./services/wattpadV4.service.js";
import WattpadV5Service from "./services/wattpadV5.service.js";
import KeywordsService from "./services/keywords.service";
import infiniteScroll from "vue-infinite-scroll";
import VueSession from "vue-session";
import VueConfirmDialog from "vue-confirm-dialog";
import LightTimeline from "vue-light-timeline";
import AnimatedVue from 'animated-vue'
import 'animate.css/animate.css'

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
Vue.use(LightTimeline);
Vue.use(VueSession);
Vue.use(infiniteScroll);


Vue.use(AnimatedVue)
// Set the base URL of the API

ApiService.init(process.env.VUE_APP_MLAB_KEY, process.env.VUE_APP_MLAB_DB);
WattpadV2Service.init("https://www.wattpad.com/apiv2/");
WattpadV3Service.init("https://www.wattpad.com/api/v3/");
KeywordsService.init(
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vTqe9RO1nI-tcZ64cVKkrZD723_HBLV0ge25ircpUvOS68O9-OGH7aGzzd3F38w0KZ7COvYfurD9vaR/pubhtml#"
);
WattpadV4Service.init("https://www.wattpad.com/v4/");
WattpadV5Service.init("https://www.wattpad.com/v5/");

// If token exists set header
/*if (TokenService.getToken()) {
  ApiService.setHeader()
}*/

Vue.use(VueRouter);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
