<script>
import {
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
} from "mdbvue";

export default {
  components: {
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
  },
  props: {
    title: { type: String, default: "Your answer" },
    propertyName: String,
    options: {
      type: Array,
      default: () => ["Yes", "No", "I don't know", "N/A"],
    },
    initVal: String,
  },
  data() {
    return { value: false };
  },
  methods: {
    change(value) {
      this.value = value;
      this.$emit("change", value, this.propertyName);
    },
  },
  created() {
    this.value = this.initVal;
  },
};
</script>

<template>
  <mdb-dropdown>
    <mdb-dropdown-toggle
      color="primary"
      size="md"
      slot="toggle"
      class="z-depth-0"
    >
      <span v-if="value">{{ value }}</span
      ><span v-else>{{ title }}</span>
    </mdb-dropdown-toggle>
    <mdb-dropdown-menu class="displayoverflow">
      <mdb-dropdown-item
        @click="change(c)"
        v-for="c in options"
        v-bind:key="c"
        >{{ c }}</mdb-dropdown-item
      >
    </mdb-dropdown-menu>
  </mdb-dropdown>
</template>