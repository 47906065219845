<script>
import WattysList from "./WattysList.vue";
import { wattysConf } from "../../conf/wattys.conf";

import {
  mdbContainer,
  mdbInput,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbRow,
} from "mdbvue";

import { langConf } from "@/conf/language.conf.js";

export default {
  components: {
    mdbContainer,
    mdbInput,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbRow,
    WattysList,
  },
  data() {
    return {
      lang: localStorage.wattys_lang
        ? JSON.parse(localStorage.wattys_lang)
        : false,
      step: localStorage.wattys_step ? localStorage.wattys_step : false,
      stepIndex: localStorage.wattys_stepIndex
        ? localStorage.wattys_stepIndex
        : false,
      stepsConf: wattysConf.steps
        .map((o) => o.name)
        .filter((o) => o != "common"),
      langConf: langConf.filter((item) => item.wattys),
      judge: localStorage.wattys_judge ? localStorage.wattys_judge : false,
      stats: false,
      loading: false,
      loadingMessage: "",
    };
  },
  filters: {},
  methods: {
    setLang(lang) {
      localStorage.wattys_lang = JSON.stringify(lang);
      this.lang = lang;
    },
    setStep(step, index) {
      localStorage.wattys_step = step;
      localStorage.wattys_stepIndex = Number(index);
      this.stepIndex = Number(index);
      this.step = step;
    },
    setJudge(judge) {
      localStorage.wattys_judge = judge;
      this.judge = judge;
    },
    setLoadingStep(loading, message) {
      this.loading = loading;
      this.loadingMessage = message;
    },
  },
};
</script>

<template>
  <mdb-container>
    <mdb-input
      class="mt-0 mb-3"
      placeholder="Your judge name (the one used on the spreadsheet)"
      ariaDescribedBy="button-addon2"
      v-model="judge"
      v-on:change="setJudge(judge)"
    >
      <mdb-dropdown slot="prepend">
        <mdb-dropdown-toggle
          color="primary"
          size="md"
          slot="toggle"
          class="z-depth-0"
        >
          <span v-if="lang">{{ lang.title }}</span>
          <span v-else>Your judge language</span>
        </mdb-dropdown-toggle>
        <mdb-dropdown-menu class="displayoverflow">
          <mdb-dropdown-item
            v-on:click="setLang(l)"
            v-for="l in langConf"
            v-bind:key="l.id"
            >{{ l.title }}</mdb-dropdown-item
          >
        </mdb-dropdown-menu>
      </mdb-dropdown>
      <mdb-dropdown slot="prepend">
        <mdb-dropdown-toggle
          color="primary"
          size="md"
          slot="toggle"
          class="z-depth-0"
        >
          <span v-if="step">{{ step }}</span>
          <span v-else>The step of your journey</span>
        </mdb-dropdown-toggle>
        <mdb-dropdown-menu class="displayoverflow">
          <mdb-dropdown-item
            v-on:click="setStep(item, index)"
            v-for="(item, index) in stepsConf"
            v-bind:key="index"
            >{{ item }}</mdb-dropdown-item
          >
        </mdb-dropdown-menu>
      </mdb-dropdown>
    </mdb-input>
    <mdb-container v-if="loading">
      <mdb-row class="d-flex justify-content-center">
        <p>{{ loadingMessage }}</p>
      </mdb-row>
      <mdb-row class="d-flex justify-content-center">
        <div class="spinner-border text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div></mdb-row
      >
    </mdb-container>
    <mdb-container v-if="lang && step && judge">
      <WattysList
        v-bind:key="step + judge + lang"
        :step="step"
        :stepIndex="Number(stepIndex)"
        :spreadSheet="lang.wattys"
        :judge="judge"
        @loadingStep="setLoadingStep"
      />
    </mdb-container>
  </mdb-container>
</template>