import router from "../router/router.js";
import { bookmarkService } from "./bookmarks.service.js";
import WattpadV4Service from "./wattpadV4.service.js";
import WattpadV3Service from "./wattpadV3.service.js";
import { actitiviesService } from "../services/activities.service";
import StoryService from "./story.service.js";
 import moment from "moment";
import _ from "underscore";
import GoogleSpreadsheetService from "./spreadsheet.service";

export const userService = {
  user: {},
  async getUserOnSpreadSheet()
  {
    let  doc = await GoogleSpreadsheetService.init("1warleKZ0xKKsIpboQpGnV0lA-jKzjAds4v24WxrMlIE");
    let sheet = doc.sheetsByIndex[0];
    return await sheet.getRows();
  },
  login: async function (username, password) {
    var c = this;
    if (username && password) {
      let rows = await this.getUserOnSpreadSheet();
      let user = rows.filter(r => r.username === username && r.password === password)[0];
        if (!user)
          throw new Error("Authentification failed");
      c.user = {
        username:user.username,
        role:user.role,
        isWattysJudge:(user.WattysJudge=="TRUE")
      }    
      bookmarkService.listBookmarks(username).then(function (t) {
          (c.user.bookmarks = _.groupBy(t, "type")),
            localStorage.setItem("user", JSON.stringify(c.user)),
            router.push("/doc");
        });
    }
  },
  logout: function() {
    localStorage.removeItem("user");
    router.push("/login");
  },
  async getOtherUser(username) {
    let rows = await this.getUserOnSpreadSheet();
    return _.find(rows, { username: username });
  },
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  },
  isLogged: function() {
    return localStorage.getItem("user");
  },
  async getWPUser(username) {
    let user = await WattpadV3Service.get(`users/${username}`)
      .then(response => {
        return typeof response.data == "string"
          ? this.convertArrayStringToJson(response.data)
          : response.data;
      });
    user.stories = await this.getUserStories(user.username).then(response=>response.stories);
    for (let s in user.stories)
    {
      user.stories[s] = await StoryService.getStory(user.stories[s].id);
    }

    user.activities = await actitiviesService.list({ wpuser: user.username }, { date: -1 });
    for (let a in user.activities) {
      let activity = user.activities[a];
    user.activities[a] = Object.assign({}, activity, {
      date: moment(new Date(activity.date)).format(
        "MM-DD-YYYY HH:mm"
      ),
      content:
        activity.user +
        " - " +
        activity.activity.split("-").join(" ") +
        " - " +
        activity.storyTitle,
      icon: actitiviesService.actionsList[activity.activity].icon,
      color:
        actitiviesService.actionsList[activity.activity].color
    });
  }
    return user;
  },
  closeWpUser(user, reason) {
    return WattpadV3Service.post(`users/${user}/close`, { reason: reason });
  },
  getUserStories(user) {
    return WattpadV4Service.get(
      `users/${user}/stories/published?limit=100`
    ).then(response => {
      return typeof response.data == "string"
        ? this.convertArrayStringToJson(response.data)
        : response.data;
    });
  },
  updateBookMarks(bookmarks) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.bookmarks = bookmarks;
    localStorage.setItem("user", JSON.stringify(user));
  },
  convertArrayStringToJson(string) {
    var str = string
      .replace(/array \(/g, "{")
      .replace(/\)/g, "}")
      .replace(/=>/g, ":")
      .replace(/\{\n\s*0 :/g, "[")
      .replace(/\n\s*\d* :/g, "")
      .replace(/,\n\s*},\n\s*},/g, "\n}\n],")
      .replace(/(, \d*|,\s*'[a-zA-Z0-9_.éèàê]*'),\n\s*}/g, "$1]")
      .replace(/\s*{\n\s*}/g, "[]")
      .replace(/\n*/g, "")
      .replace(/\s\s\s*/g, "")
      .replace(/,}/g, "}")
      .replace(/'/g, '"')
      .replace(/\\"/g, "'");
    return JSON.parse(str);
  }
};
