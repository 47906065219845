<script>
import moment from "moment";
import { mdbJumbotron, mdbRow, mdbCol, mdbView, mdbBadge } from "mdbvue";
import WordCount from "./partials/WordCount.vue";

export default {
  components: {
    mdbJumbotron,
    mdbRow,
    mdbCol,
    mdbView,
    mdbBadge,
    WordCount,
  },
  props: {
    story: Object,
  },
  data() {
    return { wordCount: false };
  },
  filters: {
    formatDate(date) {
      return moment(date).format("MM-DD-YYYY");
    },
    formatMillier(nb) {
      console.log(nb);
      return new Intl.NumberFormat().format(
        parseInt(Number(nb) * Math.pow(10, 2) + 0.5) / Math.pow(10, 2)
      );
    },
  },
  methods: {
    async wordCountEvent(wordCount) {
      this.wordCount = wordCount;
      if (this.story["Additional Notes"])
        this.story["Additional Notes"] =
          this.story["Additional Notes"] + "\n  Word count : " + wordCount;
    },
  },
  computed: {
    mature_themes() {
      return this.story.mature_themes.split(",");
    },
    sexual_content() {
      return this.story.sexual_content.split(",");
    },
  },
};
</script>

<template>
  <mdb-jumbotron class="mb-0 hoverable p-4">
    <mdb-row>
      <mdb-col md="3" offsetMd="1" class="m-3">
        <mdb-view
          :src="story.wpStory.cover"
          :alt="story.wpStory.title"
          class="mb-2"
        >
          <a :href="'http://' + story['story link']" target="_blank"></a>
        </mdb-view>
        <ul class="m-0 p-0" style="list-style: none">
          <li class="d-flex w-100 justify-content-between">
            <small><strong>Wattys sub date:</strong></small>
            <span>{{ story.date_submitted }}</span>
          </li>
          <li class="d-flex w-100 justify-content-between">
            <small><strong>Story's ID:</strong></small>
            <span>{{ story.story_id }} </span>
          </li>
          <li class="d-flex w-100 justify-content-between">
            <small><strong>Author:</strong></small>
            <span>
              <a :href="'https://www.wattpad.com/user/' + story.user_name"
                ><strong>{{ story.user_name }}</strong></a
              ></span
            >
          </li>
          <li class="d-flex w-100 justify-content-between">
            <small><strong>Publication date:</strong></small>
            <span>{{ story.wpStory.createDate | formatDate }}</span>
          </li>
          <li v-if="mature_themes.length > 0">
            <small><strong>Mature:</strong></small>
            <div class="justify-content-left">
              <mdb-badge
                v-for="theme in mature_themes"
                v-bind:key="theme"
                class="m-1"
                color="primary"
              >
                {{ theme }}</mdb-badge
              >
            </div>
          </li>
          <li v-if="sexual_content.length > 0">
            <small><strong>Sexual content:</strong></small>
            <div class="justify-content-left">
              <mdb-badge
                v-for="theme in sexual_content"
                v-bind:key="theme"
                class="m-1"
                color="primary"
              >
                {{ theme }}</mdb-badge
              >
            </div>
          </li>
          <li
            class="d-flex justify-content-between align-items-center"
            :class="{
              'grey-text': !wordCount,
              'red-text': wordCount < 40000,
              'orange-text': wordCount > 40000 && wordCount < 50000,
              'green-text': wordCount > 50000,
            }"
          >
            <small><strong>Word count:</strong></small>
            <word-count
              v-if="!wordCount"
              :story_id="story.story_id"
              @wordCount="wordCountEvent"
            />
            <span v-else>{{ wordCount | formatMillier }}</span>
          </li>
        </ul>
        <hr />
        <div class="orange-text text-left" v-if="story.alerts.length > 0">
          <h6 class="h6 mb-1">Warning :</h6>
          <ul class="m-0 p-0 pl-2 text-left" style="list-style-type: '⚠️'">
            <li v-for="alert in story.alerts" v-bind:key="alert" class="pl-1">
              {{ alert }}
            </li>
          </ul>
          <hr />
        </div>
        <div class="blue-text" v-if="story.wpStory.activities.length > 0">
          <h6 class="h6 mb-1">Content team activity:</h6>
          <ul class="m-0 pr-1 text-left" style="list-style-type: '➡️'">
            <li
              v-for="activity in story.wpStory.activities"
              v-bind:key="activity.activity"
            >
              {{ activity.activity }}
            </li>
          </ul>
          <hr />
        </div>
        <a class="my-2" :href="'/story/' + story.story_id" target="_blank"
          >›› Full analysis</a
        >
      </mdb-col>
      <mdb-col md="8" class="text-md-left ml-3 mt-3">
        <h4 class="h4">
          <a :href="'http://' + story['story link']" target="_blank">{{
            story.title
          }}</a>
        </h4>
        <slot></slot>
      </mdb-col>
    </mdb-row>
  </mdb-jumbotron>
</template>