import axios from 'axios'

const WattpadV2Service = {
    baseURL:'',
    init(baseURL) {
        this.baseURL = baseURL;
    },
    get(resource) {
        return axios.get(`${this.baseURL}${resource}`)
    },
    customRequest(data) {
        return axios(data)
    },
}

export default WattpadV2Service