<template>
<div>
  <mdb-tooltip trigger="hover" :options="{placement: 'top'}" slot="append" group class="mt-10">
      <span slot="tip">Bookmark-it !</span>
      <a v-if="!busy" v-on:click="bookmarkIt()" slot="reference" >
        <mdb-icon v-if="isNotBookmark()" style="height: 35px;line-height: 35px;margin-left: 10px;cursor:pointer"  icon="star" size="lg" class="grey-text"   aria-hidden="true" />
        <mdb-icon v-else style="height: 35px;line-height: 35px;margin-left: 10px;cursor:pointer"  icon="star" size="lg" class="amber-text"   aria-hidden="true" />
      </a>
        <div v-else class="d-flex justify-content-center">
      <div  class="spinner-grow text-light" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
  </mdb-tooltip>
</div>
</template>

<script>
import { bookmarkService } from '../services/bookmarks.service.js';
import { userService } from '../services/user.service.js';
import _  from 'underscore';
 import { mdbTooltip,
        mdbIcon } from 'mdbvue';
export default {
  name: 'BookmarkButton',
  components: {
        mdbTooltip,
        mdbIcon
      },
  props:{
    bookmark:{
      type: String
    },
    type:{
      type: String
    },
    text:{
      type: String
    }
  },
  data () {
    return {
      busy:false
    }
  },
  methods:{
    isNotBookmark()
    {
      return (_.find(this.$root.bookmarks[this.type], {user:userService.getUser().username, type:this.type, bookmark:this.bookmark}))?false:true;
    },
    bookmarkIt()
    {
      this.busy = true;
      var bookmark = this.bookmark;
      if(this.isNotBookmark())
        bookmarkService.addBookmarks(this.type, bookmark).then(()=>{
          if(!this.$root.bookmarks[this.type])
            this.$root.bookmarks[this.type]=[];
          this.$root.bookmarks[this.type].push({user:userService.getUser().username, type:this.type, bookmark:this.bookmark})
          userService.updateBookMarks(this.$root.bookmarks);
          this.busy=false;
        })
      else
      {
        let actualbmIndex = _.findLastIndex(this.$root.bookmarks[this.type], {user:userService.getUser().username, type:this.type, bookmark});
          bookmarkService.findOne({user:userService.getUser().username,  type:this.type, bookmark:this.bookmark}).then(bookmark=>{
            bookmarkService.deleteBookmarks(bookmark._id['$oid']).then(()=>{
              this.$root.bookmarks[this.type].splice(actualbmIndex, 1);
              userService.updateBookMarks(this.$root.bookmarks);
              this.busy=false;
            })
          });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}
</style>
