<script>
import { mdbInput } from "mdbvue";

export default {
  components: {
    mdbInput,
  },
  props: {
    label: String,
    propertyName: String,
    initVal: String,
  },
  data() {
    return { value: false };
  },
  methods: {
    change() {
      this.$emit("change", this.value, this.propertyName);
    },
  },
  created() {
    this.value = this.initVal;
  },
};
</script>
    
<template>
  <mdb-input class="w-100" v-model="value" @blur="change" :rows="1" />
</template>
    