<script>
import WattyService from "../../services/wattys.service";
import InitialStory from "./InitialStory.vue";
import LongListStory from "./LongListStory.vue";
import ShortListStory from "./ShortListStory.vue";
import WattysStory from "./WattysStory.vue";
import WattyStats from "./Statistics.vue";
import { mdbBtn } from "mdbvue";
export default {
  components: {
    InitialStory,
    LongListStory,
    ShortListStory,
    WattysStory,
    WattyStats,
    mdbBtn,
  },
  props: {
    spreadSheet: String,
    judge: String,
    step: String,
    stepIndex: Number,
  },
  data() {
    return {
      wattysList: {},
      waitingStories: [],
      editAward: false,
      award_categories: WattyService.award_categories,
      sheetConfig: WattyService.sheetConfig,
      offset: 0,
      storiesCount: 0,
      limit: 5,
      stats: [],
      load: true,
    };
  },
  filters: {},
  created() {
    this.getStories();
  },
  methods: {
    async getStories() {
      this.waitingStories = [];
      this.$emit("loadingStep", true, "Getting rows from the spreadsheet...");
      await WattyService.init(this.spreadSheet, this.stepIndex);
      this.sheetConfig = WattyService.sheetConfig;

      this.$emit(
        "loadingStep",
        true,
        "Filtering awainting " +
          this.judge +
          "'s rows and getting side info from Wattpad..."
      );
      let filter = [{ key: "Assigned Judge", value: this.judge }];
      this.storiesCount = await WattyService.filterCount(filter);
      this.waitingStories = await WattyService.filter(
        filter,
        this.offset,
        this.limit
      );
      this.$emit(
        "loadingStep",
        true,
        "Last but not the least : proccessing some stats..."
      );
      console.log("FULL LOADED");
      this.load = false;
      this.$emit("loadingStep", false);
    },
    async markAsDone(story_id) {
      await WattyService.markAsDone(story_id);
      this.waitingStories = WattyService.filteredRows;
    },
    async resolveStory(story_id, value) {
      await WattyService.setData(
        story_id,
        this.sheetConfig.validationKey,
        value,
        true
      );
      this.waitingStories = WattyService.filteredRows;
    },
    async updateCell(story_id, value, propertyName) {
      await WattyService.setData(story_id, propertyName, value);
      this.waitingStories = WattyService.filteredRows;
    },
    wordCountEvent(wordCount) {
      this.story.wordCount = Number(wordCount);
    },
    async loadMore() {
      this.offset = this.offset + this.limit;
      this.waitingStories = this.waitingStories.concat(
        await WattyService.filter(
          [{ key: "Assigned Judge", value: this.judge }],
          this.offset,
          this.limit
        )
      );
    },
    async updateStats() {
      this.stats = [
        await WattyService.getStatistics([], "🏆 My team"),
        await WattyService.getStatistics(
          [{ key: "Assigned Judge", value: this.judge }],
          "💎 Me"
        ),
        await WattyService.getCategoryStatistics("Stats by awards"),
      ];
    },
  },
};
</script>

<template>
  <div>
    <WattyStats
      v-if="!load"
      :stats="stats"
      :step="step"
      @updateStats="updateStats()"
    />
    <div class="mt-3" v-for="story in waitingStories" v-bind:key="story.key">
      <WattysStory
        v-if="!story.done"
        :story="story"
        @wordCount="(value) => (story.wordCount = value)"
      >
        <component
          v-bind:is="step + 'Story'"
          :story="story"
          :sheetConfig="sheetConfig"
          @resolveStory="resolveStory"
          @updateCell="updateCell"
          @markAsDone="markAsDone"
        />
      </WattysStory>
    </div>

    <p v-if="!load" class="text-center mt-4">
      {{ waitingStories.length }} / {{ storiesCount }} <br />
      <mdb-btn v-if="storiesCount > waitingStories.length" @click="loadMore()">
        Load more
      </mdb-btn>
    </p>
  </div>
</template>