<script>
import VueToggles from "vue-toggles";
export default {
  components: { VueToggles },
  props: {
    label: { type: String, default: "Your answer" },
    propertyName: String,
    initVal: Boolean,
  },
  data() {
    return { value: false };
  },
  methods: {
    change() {
      this.value = !this.value;
      this.$emit("change", Boolean(this.value), this.propertyName);
    },
  },
  created() {
    this.value = Boolean(this.initVal);
  },
};
</script>

<template>
  <VueToggles @click="change()" :value="value" />
</template>
