const formDisplayConf = {
    SwitchInput: {
        col: { label : "10", field : "2"}
    },
    SimpleInput: {
        hideLabel:true,
        col: { field : "12"}
    },
    FreeText: {
        hideLabel:true,
        col: { field : "12"}
    },
    default: {
        hideLabel:false,
        col: { label : "7", field : "5"}
    },
}
export { formDisplayConf };																