<script>
import DropDown from "./DropDown.vue";
import Slider from "./Slider.vue";
import FreeText from "./FreeText.vue";
import SwitchInput from "./SwitchInput.vue";

import SimpleInput from "./SimpleInput.vue";
import TinyInput from "./TinyInput.vue";
//import ResolveBar from "../partials/ResolveBar.vue";
import CategorySwitch from "../partials/CategorySwitch.vue";
import { formDisplayConf } from "@/conf/formDisplay.conf.js";

import { mdbRow, mdbCol, mdbBtn } from "mdbvue";
export default {
  components: {
    mdbRow,
    mdbCol,
    DropDown,
    Slider,
    CategorySwitch,
    // ResolveBar,
    FreeText,
    SwitchInput,
    SimpleInput,
    TinyInput,
    mdbBtn,
  },
  props: {
    story: Object,
    sheetConfig: Object,
    form: Array,
  },
  data() {
    return {
      formConf: formDisplayConf,
    };
  },
  methods: {
    updateCell(value, propertyName) {
      this.story[propertyName] = value;
      this.$emit("updateCell", value, propertyName);
    },
    async addNotesAndResolve(value) {
      if (this.isFull()) {
        this.$emit("resolveStory", value);
      }
    },
    async markAsDone() {
      if (this.isFull()) {
        this.$emit("markAsDone");
      }
    },
    isFull() {
      if (
        this.story[this.sheetConfig.validationKey] == "N/A" &&
        this.story[this.sheetConfig.notesKey] != ""
      )
        return true;
      let mj = this.sheetConfig.columns
        .filter((i) => i.field && !i.notrequired)
        .map((i) => {
          if (i.category) {
            if (i.category == this.story["Confirm Category"])
              return {
                field: i.field,
                validation: this.story[i.propertyName] != "",
              };
            else return { field: i.field, validation: true };
          }
          return {
            field: i.label,
            validation: this.story[i.propertyName] != "",
          };
        });
      return mj.map((i) => i.validation).indexOf(false) == -1;
    },
  },
};
</script>

<template>
  <form>
    <slot name="header"> </slot>
    <div class="mb-4" v-for="(part, index) in form" v-bind:key="index">
      <h5>{{ part.title }}</h5>
      <CategorySwitch
        v-if="part.title == `Category's specificities`"
        :story="story"
        @updateCell="updateCell"
      />
      <div v-if="part.title == `Longlist Review`" class="mb-2">
        <slot name="llr"></slot>
      </div>
      <div v-for="(field, index) in part.fields" v-bind:key="index">
        <mdb-row class="d-flex align-self-center">
          <mdb-col
            sm="12"
            :md="
              formConf[field.field] &&
              formConf[field.field].col &&
              formConf[field.field].col.label
                ? formConf[field.field].col.label
                : formConf['default'].col.label
            "
            v-if="
              formConf[field.field] ? !formConf[field.field].hideLabel : true
            "
          >
            <label>{{ field.label }}</label>
          </mdb-col>
          <mdb-col
            :md="
              formConf[field.field]
                ? formConf[field.field].col.field
                : formConf['default'].col.field
            "
            sm="10"
            class="d-flex justify-content-end"
          >
            <component
              v-if="field.fieldOptions"
              v-bind:is="field.field"
              :initVal="story[field.propertyName]"
              v-model="story[field.propertyName]"
              @change="updateCell"
              :propertyName="field.propertyName"
              :label="field.label"
              :options="field.fieldOptions"
            />
            <component
              v-else
              v-bind:is="field.field"
              :initVal="story[field.propertyName]"
              v-model="story[field.propertyName]"
              @change="updateCell"
              :propertyName="field.propertyName"
              :label="field.label"
            />
          </mdb-col>
        </mdb-row>
        <hr
          v-if="formConf[field.field] ? !formConf[field.field].hideLabel : true"
        />
      </div>
    </div>

    <div v-if="isFull()" class="text-center py-4 mt-3">
      <mdb-btn @click="markAsDone()">Done !</mdb-btn>
      <!-- <ResolveBar :story="story" @resolveStory="addNotesAndResolve" /> -->
    </div>
  </form>
</template>