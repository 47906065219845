<template>
  <section id="profile" v-if="user != null">
    <mdb-row>
      <mdb-col md="6" sm="12" lg="5">
        <mdb-card cascade narrow>
          <mdb-card-body class="text-center">
            <mdb-card-title class="font-bold mb-2">
              <strong>{{ user.username }}</strong>
            </mdb-card-title>
            <h5 class="indigo-text">
              <strong>{{ roles | listToString }}</strong>
            </h5>
            <p>You did {{ activitiesCount }} activities so far !</p>
            <p class="font-weight-light">
              On this page, you can see up to 400 🤯 of your last activities
            </p>
            <mdb-list-group>
              <mdb-list-group-item
                v-bind:key="index"
                v-for="(activity, index) in activities"
                :active="active2 == index + 1"
                @click.native.prevent="
                  loadActivity(activity);
                  active2 = index + 1;
                "
              >
                {{ activity.id }}
                <mdb-badge color="primary" pill>{{ activity.count }}</mdb-badge>
              </mdb-list-group-item>
            </mdb-list-group>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col md="6" lg="7" sm="12" v-if="!loading">
        <mdb-row class="mt-4" fuild>
          <mdb-container>
            <mdb-jumbotron class="text-left hoverable">
              <h4 class="h4 mb-4">
                {{ displayActivities.id }} ({{ displayActivities.count }})
              </h4>
              <mdb-list-group>
                <mdb-list-group-item
                  :icon="getIcon(activity.activity)"
                  v-bind:key="index"
                  v-for="(activity, index) in displayActivities.items"
                >
                  <ul
                    class="d-block"
                    style="list-style:none;margin:0;padding:0"
                  >
                    <li>
                      {{ activity.date | date }} - {{ activity.activity }}
                    </li>
                    <li v-if="activity.storyId">
                      <span v-if="needLink(activity)">
                        <a :href="'/story/' + activity.storyId" target="_blank"
                          >{{ activity.storyId }} - {{ activity.storyTitle }}</a
                        >
                        <br /><small v-if="lastActivity(activity)"
                          >last activity after you :
                          {{ lastActivity(activity).activity }}</small
                        >
                      </span>
                      <span v-else>{{ activity.storyId }}</span>
                    </li>
                    <li v-else>{{ activity.wpuser }}</li>
                  </ul>
                </mdb-list-group-item>
              </mdb-list-group>
            </mdb-jumbotron>
          </mdb-container>
        </mdb-row>
      </mdb-col>
      <mdb-col md="6" lg="7" sm="12" v-else>
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </mdb-col>
    </mdb-row>
  </section>
</template>

<script>
import {
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardTitle,
  mdbCardBody,
  mdbJumbotron,
  mdbListGroup,
  mdbListGroupItem,
  mdbBadge,
  mdbContainer,
} from "mdbvue";

import { userService } from "../services/user.service";
import { actitiviesService } from "../services/activities.service";
import _ from "underscore";
import moment from "moment";

export default {
  name: "Profile",
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardTitle,
    mdbCardBody,
    mdbListGroup,
    mdbListGroupItem,
    mdbBadge,
    mdbJumbotron,
    mdbContainer,
  },
  created() {
    userService.getOtherUser(this.$route.params.username).then((user) => {
      this.user = user;
      let actiProm = [];
      for (let a in actitiviesService.actionsList) {
        if (
          actitiviesService.actionsList[a].allowedRoles.indexOf(
            this.user.role
          ) > -1
        )
          actiProm.push(
            actitiviesService
              .count({
                user: this.user.username,
                activity: actitiviesService.actionsList[a].id,
              })
              .then((response) => {
                this.activities.push(
                  _.extend(actitiviesService.actionsList[a], {
                    count: response,
                  })
                );
              })
          );
      }
      Promise.all(actiProm).then(() => {
        actitiviesService
          .count({
            user: this.user.username,
            activity: { $in: _.pluck(this.activities, "id") },
          })
          .then((response) => {
            this.activitiesCount = response;
          })
          .then(() => {
            actitiviesService
              .list(
                {
                  user: this.user.username,
                  activity: { $in: _.pluck(this.activities, "id") },
                },
                { date: -1 },
                400
              )
              .then((response) => {
                this.activities.unshift({
                  id: "All",
                  count: response.length,
                  items: response,
                });
                this.displayActivities = this.activities[0];
                actitiviesService
                  .list({
                    storyId: {
                      $in: _.pluck(this.displayActivities.items, "storyId"),
                    },
                  })
                  .then((response) => {
                    this.storiesActivities = _.groupBy(response, "storyId");
                    this.loading = false;
                  });
              });
          });
      });
    });
  },
  data() {
    return {
      user: null,
      roles: "",
      active2: 0,
      activitiesCount: 0,
      activities: [],
      enablepagination: false,
      displayActivities: [],
      loading: true,
    };
  },
  filters: {
    listToString(string) {
      return string.toString().replace(",", ", ");
    },
    date(date) {
      return moment(date).format("MM-DD-YYYY");
    },
  },
  methods: {
    getIcon(activity) {
      return actitiviesService.actionsList[activity]
        ? actitiviesService.actionsList[activity].icon
        : "user";
    },
    needLink(activity) {
      let activitiesOnStory = _.pluck(
        this.storiesActivities[activity.storyId],
        "activity"
      );
      if (
        activitiesOnStory.indexOf("delete-banned") > -1 ||
        activitiesOnStory.indexOf("delete-copyright") > -1 ||
        activitiesOnStory.indexOf("ts-report-cesm") > -1 ||
        activitiesOnStory.indexOf("report-CESM") > -1 ||
        activitiesOnStory.indexOf("already-removed") > -1
      ) {
        //"already-removed"
        return false;
      }
      return true;
    },
    loadActivity(activity) {
      if (activity.items) this.displayActivities = activity;
      else {
        this.loading = true;
        actitiviesService
          .list(
            { user: this.user.username, activity: activity.id },
            { date: -1 },
            400
          )
          .then((response) => {
            let actiIndex = _.findIndex(this.activities, { id: activity.id });
            this.activities[actiIndex].items = response;
            this.displayActivities = this.activities[actiIndex];
            this.loading = false;
          });
      }
    },
    lastActivity(activity) {
      let last = this.storiesActivities[activity.storyId];
      if (!last) return false;
      let la = last[this.storiesActivities[activity.storyId].length - 1];
      if (la && activity.activity != la.activity) return la;
      return false;
    },
  },
};
</script>

<style scoped>
.profile-card-footer {
  background-color: #f7f7f7 !important;
  padding: 1.25rem;
}
.card.card-cascade .view {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15), 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}
</style>
