<template>
  <section id="profile">
    <div v-if="wpUser != null">
      <mdb-row>
        <mdb-col md="3">
          <mdb-card class="testimonial-card mb-5">
            <div
              class="card-up indigo lighten-1"
              :style="'background-image:url(' + wpUser.backgroundUrl + ')'"
            ></div>
            <div class="avatar mx-auto white">
              <img
                :src="wpUser.avatar"
                class="rounded-circle"
                alt="woman avatar"
              />
            </div>
            <mdb-card-body class="text-center">
              <mdb-card-title class="font-bold mb-2">
                <a
                  :href="'https://www.wattpad.com/user/' + wpUser.username"
                  taget="_blank"
                  ><strong>{{ wpUser.username }}</strong></a
                >
              </mdb-card-title>
              <h5 class="indigo-text">
                <strong>{{ wpUser.name }}</strong>
              </h5>
              <mdb-badge v-if="wpUser.numFollowers >= 10000" color="danger">
                {{ wpUser.numFollowers }} followers</mdb-badge
              >
              <mdb-badge v-else color="grey">
                {{ wpUser.numFollowers }} followers</mdb-badge
              >
              <p style="font-size: 11px" class="font-italic">
                Created at {{ wpUser.createDate | formatDate }}
              </p>
              <p style="font-size: 10px">
                <span v-if="wpUser.ambassador">Ambassador</span>
                <span v-if="wpUser.star">Star</span>
              </p>
            </mdb-card-body>

            <mdb-card-footer>
              <bookmark-button
                style="float: right"
                :bookmark="'user/' + wpUser.username"
                type="user"
              />
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
        <mdb-col
          v-if="
            user.username == 'cestdoncvrai' || user.username == 'mesdamesduc'
          "
        >
          <mdb-card class="testimonial-card mb-5">
            <mdb-card-body>
              <strong> Actions : </strong>
              <p>
                <a
                  v-if="
                    user.role != 'content' &&
                    user.role != 'none' &&
                    user.role != 'copyright-diver'
                  "
                  v-on:click="
                    addActivity(
                      'close-account-' + wpUser.username + '-banned_explicit'
                    )
                  "
                  class="btn-primary btn-sm btn btn-icon"
                >
                  <mdb-icon icon="hammer" class="pr-1" /> Close for Banned
                </a>
                <a
                  v-if="
                    user.role != 'content' &&
                    user.role != 'none' &&
                    user.role != 'banned-diver'
                  "
                  v-on:click="
                    addActivity(
                      'close-account-' + wpUser.username + '-copyright'
                    )
                  "
                  class="btn-primary btn-sm btn btn-icon"
                >
                  <mdb-icon icon="copyright" class="pr-1" /> Close for Copyright
                </a>
                <a
                  v-if="user.role != 'content' && user.role != 'none'"
                  v-on:click="
                    addActivity(
                      'close-account-' +
                        wpUser.username +
                        '-cyberbullying_harassment'
                    )
                  "
                  class="btn-primary btn-sm btn btn-icon"
                >
                  <mdb-icon icon="sad-tear" class="pr-1" /> Close for harrasment
                </a>
                <a
                  v-if="user.role != 'content' && user.role != 'none'"
                  v-on:click="
                    addActivity(
                      'close-account-' + wpUser.username + '-underage'
                    )
                  "
                  class="btn-primary btn-sm btn btn-icon"
                >
                  <mdb-icon icon="baby" class="pr-1" /> Close for underage
                </a>
                <!-- <a v-if="user.role!='content' && user.role!='none'" v-on:click="addActivity('report-account-'+wpUser.username+'-TS')" target="_blank" :href="'https://www.wattpad.com/help/report/?account=https://www.wattpad.com/user/'+wpUser.username" class="btn-primary btn-sm btn  btn-icon">
                <mdb-icon icon="level-up-alt" class="pr-1"/> Report to T&S
              </a> -->
                <a
                  v-on:click="
                    addActivity('close-account-' + wpUser.username + '-spam')
                  "
                  target="_blank"
                  :href="''"
                  class="btn-primary btn-sm btn btn-icon"
                >
                  <mdb-icon icon="exclamation-circle" class="pr-1" /> Close for
                  spam
                </a>
                <a
                  v-on:click="
                    addActivity(
                      'close-account-' + wpUser.username + '-poaching'
                    )
                  "
                  target="_blank"
                  :href="''"
                  class="btn-primary btn-sm btn btn-icon"
                >
                  <mdb-icon icon="mail-bulk" class="pr-1" /> Close for poaching
                </a>
              </p>
            </mdb-card-body>
          </mdb-card>
          <mdb-card class="testimonial-card mb-5">
            <mdb-card-body>
              <mdb-card-title class="font-bold mb-2">
                <strong>Writer's description</strong>
              </mdb-card-title>
              <div
                class="text-break mt-2"
                style="
                  white-space: break-spaces;
                  max-height: 350px;
                  overflow: auto;
                "
              >
                {{ wpUser.description }}
              </div>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col md="7">
          <mdb-card-title class="font-bold mb-2">
            <h2>{{ wpUser.username }}'s stories</h2>
          </mdb-card-title>
          <mdb-row>
            <mdb-col
              md="3"
              sm="6"
              class="mb-5"
              v-for="(story, index) in wpUser.stories"
              v-bind:index="index"
              v-bind:key="story.id"
            >
              <mdb-card reverse>
                <mdb-view hover>
                  <a :href="'/story/' + story.id" target="_blank">
                    <mdb-card-image
                      flex-center
                      :src="story.cover"
                      alt="Card image cap"
                    ></mdb-card-image>
                    <mdb-mask
                      flex-center
                      waves
                      overlay="white-slight"
                      :class="story.markerAsCleanBy ? 'clean' : ''"
                    ></mdb-mask>
                  </a>
                </mdb-view>
                <mdb-card-body class="text-center">
                  <h6>{{ story.title }}</h6>
                  <story-flag :story="story"></story-flag>
                </mdb-card-body>
              </mdb-card>
            </mdb-col>
          </mdb-row>
        </mdb-col>
        <mdb-col md="5">
          <mdb-card class="d-flex mb-5">
            <mdb-card-header>
              <mdb-card-title class="font-bold mb-3">
                <strong>Moderation Logs</strong>
              </mdb-card-title>
            </mdb-card-header>
            <mdb-card-body style="overflow: auto">
              <light-timeline
                :items="wpUser.activities"
                v-if="wpUser.activities.length > 0"
              >
                <template slot="tag" slot-scope="{ item }">
                  {{ item.date }}
                </template>
                <template slot="symbol" slot-scope="{ item }">
                  <i
                    :class="
                      'fas fa-lg fa-' + item.icon + ' ' + item.color + '-text'
                    "
                  ></i>
                </template>
                <template slot="content" slot-scope="{ item }">
                  {{ item.content }}
                </template>
              </light-timeline>
              <p v-else>No logs... yet !</p>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </div>
    <div v-else-if="busy && !error" class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="error" class="d-flex justify-content-center">
      <div class="text-center">
        <p>{{ error }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  /*  mdbListGroup,
    mdbListGroupItem, */
  mdbBadge,
  mdbMask,
  mdbView,
  mdbCardImage,
  mdbCardHeader,
  mdbCardFooter,
  mdbIcon,
} from "mdbvue";
import { userService } from "../services/user.service";
import { bookmarkService } from "../services/bookmarks.service";
import actitiviesService from "../services/activities.service";

import BookmarkButton from "./BookmarkButton.vue";
import StoryFlag from "./storyFlag.vue";
import moment from "moment";

export default {
  name: "WPUser",
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardHeader,
    BookmarkButton,
    /*    mdbListGroup,
    mdbListGroupItem,*/
    mdbBadge,
    mdbIcon,
    mdbMask,
    mdbView,
    mdbCardImage,
    mdbCardFooter,
    StoryFlag,
  },
  created() {
    this.user = userService.getUser();
    userService
      .getWPUser(this.$route.params.username)
      .then((user) => {
        this.wpUser = user;
        this.busy = false;
      })
      .catch((error) => {
        if (error.response) {
          let errorD = error.response.data;
          if (errorD.error_code == 1014) {
            bookmarkService
              .list({
                bookmark: "user/" + this.wpUser,
                username: this.user.username,
              })
              .then((response) => {
                for (let r in response)
                  bookmarkService.deleteBookmarks(response[r]._id.$oid);
              });
          }
          this.error = errorD.message;
        }
        console.log(error);
      });
  },
  data() {
    return {
      user: null,
      wpUser: null,
      roles: "",
      error: false,
      busy: true,
    };
  },
  methods: {
    addActivity(activity) {
      if (this.wpUser.numFollowers > 10000 && this.roleIs("divers"))
        alert(
          "Be careful, this user has more than 10000 followers. Please inform the T&S before do anything."
        );
      var reason = activity.split("-");
      reason.shift();
      reason.shift();
      reason = reason.join(" for ");
      this.$confirm({
        message: `"You are blocking ${reason}. Are you sure ?"`,
        button: {
          yes: "Absolutly",
          no: "Nevermind",
        },
        callback: (confirm) => {
          if (confirm) {
            userService
              .closeWpUser(this.wpUser.username, reason.split(" ").pop())
              .then(() => {
                actitiviesService.add({
                  activity: activity,
                  wpUser: this.wpUser.username,
                });
              });
          }
        },
      });
    },
  },
  filters: {
    listToString(string) {
      return string.toString().replace(",", ", ");
    },
    formatDate(date) {
      return moment(date).format("MM-DD-YYYY");
    },
  },
};
</script>

<style scoped>
.profile-card-footer {
  background-color: #f7f7f7 !important;
  padding: 1.25rem;
}
.card.card-cascade .view {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15), 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}
.testimonial-card .avatar {
  width: 120px;
  margin-top: -60px;
  overflow: hidden;
  border: 5px solid #fff;
  border-radius: 50%;
}
.testimonial-card .avatar img {
  width: 100%;
}
.testimonial-card .card-up {
  height: 180px;
  overflow: hidden;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-size: auto;
  background-attachment: fixed;
}
</style>
