<script>
import StoryService from "@/services/story.service";

import { mdbIcon } from "mdbvue";

export default {
  components: {
    mdbIcon,
  },
  props: {
    story_id: String,
  },
  data() {
    return { wordCount: 0, waitForWC: false };
  },
  methods: {
    async getWordCount() {
      this.waitForWC = true;
      this.wordCount = await StoryService.getStoryWordCount(this.story_id);
      this.waitForWC = false;
      this.$emit("wordCount", this.wordCount);
    },
  },
};
</script>

<template>
  <span>
    <a
      v-if="!wordCount && !waitForWC"
      @click="getWordCount()"
      class="primary-text"
      ><mdb-icon
        icon="arrow-alt-circle-down"
        class="mr-1 primary-text text-primary"
    /></a>
    <span v-if="waitForWC" class="spinner-grow text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </span>
    <span v-if="wordCount > 0"> {{ wordCount }}</span>
  </span>
</template>