<script>
import { mdbInput } from "mdbvue";

export default {
  components: {
    mdbInput,
  },
  props: {
    label: String,
    propertyName: String,
    initVal: String,
  },
  data() {
    return { value: false };
  },
  methods: {
    change() {
      this.$emit("change", this.value, this.propertyName);
    },
  },
  created() {
    this.value = this.initVal;
  },
};
</script>

<template>
  <mdb-input
    class="w-100"
    type="textarea"
    v-model="value"
    @blur="change"
    :label="label"
    :data-mdb-showcounter="true"
    :rows="5"
    icon="pencil-alt"
    wrapperClass="active-amber-textarea"
  />
</template>
