<template>
    <mdb-tooltip v-if="activity.tooltip" trigger="hover" :options="{placement: 'top'}">
        <span slot="tip">{{activity.tooltip}}</span>
        <span href="#"  slot="reference" class="btn-primary btn-sm btn btn-icon">
            <mdb-icon :icon="activity.icon" class="mr-1"/> {{activity.label}}
        </span>
    </mdb-tooltip>
    <span v-else  slot="reference"  class="btn-primary btn-sm btn btn-icon">
        <mdb-icon :icon="activity.icon" class="mr-1"/> {{activity.label}}
    </span>
</template>
<script>
import { mdbIcon
, mdbTooltip 
} from 'mdbvue';
export default {
    props:{
        activity:Object
    },
    components:{
    mdbIcon
    , mdbTooltip
    },
    methods:{

    }
}
</script>