<template>
  <ul>
    <li v-if="story.isClean">
      <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
        <span slot="tip">Clean!</span>
        <a slot="reference" class="icons-sm"
          ><mdb-icon
            size="1x"
            icon="check-double"
            class="green-text pr-2 pl-2"
            aria-hidden="true"
        /></a>
      </mdb-tooltip>
    </li>
    <li v-if="story.isPaywalled == true">
      <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
        <a slot="reference" class="icons-sm"
          ><mdb-icon
            size="1x"
            icon="crown"
            class="blue-text black-text pr-2 pl-2"
            aria-hidden="true"
        /></a>
        <span slot="tip">paid story</span>
      </mdb-tooltip>
    </li>
    <li v-if="story.completed == true">
      <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
        <a slot="reference" class="icons-sm"
          ><mdb-icon
            size="1x"
            icon="stop"
            class="blue-text pr-2 pl-2"
            aria-hidden="true"
        /></a>
        <span slot="tip">Completed</span>
      </mdb-tooltip>
    </li>
    <li v-if="story.mature == true">
      <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
        <a
          slot="reference"
          class="
            font-weight-bold
            blue-text
            text-monospace text-center
            pl-0
            mature
            pr-2
          "
          >+18</a
        >
        <span slot="tip">mature</span>
      </mdb-tooltip>
    </li>
    <li v-if="story.warning">
      <a
        class="icons-sm"
        v-for="(warn, index) in story.warning"
        v-bind:key="index"
      >
        <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
          <span slot="tip">{{ warn }}</span>
          <a slot="reference"
            ><mdb-icon
              size="1x"
              :icon="getKeyWordConf(warn).icon"
              class="red-text pl-2 pr-2"
              :class="getKeyWordConf(warn).color + '-text'"
              aria-hidden="true"
          /></a>
        </mdb-tooltip>
      </a>
    </li>
    <li v-if="story.warning && story.warning.length == 0">
      <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
        <span slot="tip">Detection failed : you may take a look.</span>
        <a slot="reference" class="icons-sm"
          ><mdb-icon
            size="1x"
            icon="question-circle"
            class="far grey-text pr-2 pl-2"
            aria-hidden="true"
        /></a>
      </mdb-tooltip>
    </li>
    <li v-if="story.activities && story.isAwaiting">
      <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
        <span slot="tip"
          >{{ story.activities[story.activities.length - 1].activity }} awaiting
          for diver</span
        >
        <a slot="reference" class="icons-sm"
          ><mdb-icon
            size="1x"
            icon="clock"
            class="red-text pr-2 pl-2"
            aria-hidden="true"
        /></a>
      </mdb-tooltip>
    </li>
  </ul>
</template>

<script>
import KeywordsService from "../services/keywords.service";
import { mdbTooltip, mdbIcon } from "mdbvue";
export default {
  name: "StoryFlag",
  components: {
    mdbTooltip,
    mdbIcon,
  },
  props: {
    story: {
      type: Object,
    },
  },
  methods: {
    getKeyWordConf(warn) {
      return KeywordsService.conf[warn]
        ? KeywordsService.conf[warn]
        : { icon: "eye", color: "grey" };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}

a.mature {
  font-size: 9px;
  border: thin dashed;
  border-radius: 50%;
  line-height: 16px;
  display: inline-flex;
  width: 19px;
  margin-right: 5px;
}
ul {
  display: inline-flex;
  margin: 0;
  padding: 0;
  line-height: 0px;
}
ul li {
  list-style: none;
  display: inline-flex;
}
</style>
