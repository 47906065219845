const { GoogleSpreadsheet } = require("google-spreadsheet");

const GoogleSpreadsheetService = {

    credentials: {
        "type": process.env.VUE_APP_GOOGLE_API_ACCOUNT_TYPE,
        "project_id":process.env.VUE_APP_GOOGLE_API_PROJECT_ID,
        "private_key_id": process.env.VUE_APP_GOOGLE_API_PRIVATE_KEY_ID,
        "private_key": process.env.VUE_APP_GOOGLE_API_PRIVATE_KEY,
        "client_email":process.env.VUE_APP_GOOGLE_API_CLIENT_EMAIL,
        "client_id": process.env.VUE_APP_GOOGLE_API_CLIENT_ID,
        "auth_uri": process.env.VUE_APP_GOOGLE_API_AUTH_URI,
        "token_uri": process.env.VUE_APP_GOOGLE_API_TOKEN_URI,
        "auth_provider_x509_cert_url": process.env.VUE_APP_GOOGLE_API_AUTH_PROVIDER,
        "client_x509_cert_url":process.env.VUE_APP_GOOGLE_API_CLIENT_CERT_URL
    },
    async init(spreadSheetId) {
        this.doc = new GoogleSpreadsheet(spreadSheetId);
        await this.doc.useServiceAccountAuth(this.credentials);
        await this.doc.loadInfo();
        return this.doc;
    },
}

export default GoogleSpreadsheetService;
