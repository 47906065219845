import ApiService from './api.service'
import  { userService } from './user.service'

export const bookmarkService = {
    findOne(query)
    {
        return ApiService.findOne("bookmarks",query).then(response=>{return response.data})
    },
    list(query)
    {
        return ApiService.list("bookmarks",query).then(response=>{return response.data})
    },
    listBookmarks(username)
    {
        return ApiService.list("bookmarks",{user:username}).then(response=>{return response.data})
    },
    addBookmarks(type, bookmark)
    {
        return this.findOne({user:userService.getUser().username, type:type, bookmark:bookmark}).then(response=>{
            if(!response)
                return ApiService.add("bookmarks", {user:userService.getUser().username, type:type, bookmark:bookmark})
        })
    },
    deleteBookmarks(id)
    {
        return ApiService.delete("bookmarks", id)
    }
};
