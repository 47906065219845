import WattpadV2Service from "./wattpadV2.service.js";
import WattpadV3Service from "./wattpadV3.service.js";
import WattpadV5Service from "./wattpadV5.service.js";
import { actitiviesService }  from "../services/activities.service";
import KeywordsService from "../services/keywords.service";
import { langConf } from "../conf/language.conf";

import sanitizeHtml from "sanitize-html";
import _ from "underscore";

const StoryService = {
  words: [],
  getHotlist: async (tags, lang, offset, limit) => {
    return WattpadV5Service.get(
      `/hotlist?tags=${tags
        .split(" ")
        .join(
          ","
        )}&language=${lang}&offset=${offset}&nextUrl&limit=${limit}&wp_token=${
        process.env.VUE_APP_WP_TOKEN
      }`
    )
      .then(async (response) => {
        for (let s in response.data.stories) {
          response.data.stories[s]={... await StoryService.getStory(response.data.stories[s].id)}
        }
        return response;
      });
  },
  getFullStory: async (id) => { 
    let story = await StoryService.getStory(id);
    story.parts = await StoryService.getStoryPartsContent(story.parts);
      
      story.images = _.flatten(_.pluck(story.parts, "images"));
      story.wordsCount = _.pluck(story.parts, "wordCount").reduce(
        (a, b) => a + b,
        0
    );

    story.fulltxt = story.parts.map(o => o.content).join(" ");
    story = await StoryService.populateKeywords(story);
    return story;
   },
  getStory: async (id) => {
    let story = await WattpadV3Service.get(
      `stories/${id}?fields=parts(id,url,title,photoUrl,wordCount),completed,mature,lastPublishedPart,isPaywalled,language,categories,description,id,cover,tags,description,user(numFollowers,name,programs,wattpad_squad,is_staff,ambassador,verified),title,tags,url,createDate,modifyDate,voteCount,readCount,commentCount`
    )
      .then((data) => {
        return typeof data.data == "string"
          ? this.convertArrayStringToJson(data.data)
          : data.data;
      });
    story.modifyDate = new Date(story.lastPublishedPart.createDate)
    story.activities = await actitiviesService.listAndFormat({ storyId: story.id }, { date: -1 });
    if (story.activities.length > 0) {
      if (story.activities[0].activity.indexOf("mark-as-clean") > -1) {
        if (new Date(story.activities[0].date) > story.modifyDate) {
          story.markeAcapOn = story.activities[0].date;
          story.markeAcapBy = story.activities[0].user;
          story.isClean = true;
        } else {
          story.activities.unshift({
            date: story.lastPublishedPart.createDate,
            activity: "need-new-check",
            label: "This story has been updated since it was marked as clean. Please check again",
            user: "system-bot",
          });
        }
      }
      story.isAwaiting = story.activities[0].activity.indexOf("report") > -1;
    }
   story = await StoryService.populateKeywords(story);
    return story;
  },
  async populateKeywords(story)
  {
    let selectedLang = _.findWhere(langConf, { id: story.language.id });
    //KEYWORDS WORK
    if (selectedLang) {
      //Récupérer les mots clés
      let keywords = await KeywordsService.getKeywords(selectedLang);
      if(story.parts[0].content) {
        story.parts = await KeywordsService.populateStoriesParts(
          story.parts,
          keywords
        )
      }
      story.warning = KeywordsService.getWarning(story, keywords);
    } else {
      story.outOfLang = true;
    } 
    return story;
  },
  async getStoryWordCount(id)
  {
    let parts = await this.getStoryParts(id);    
    return parts.map(o => o["wordCount"]).reduce(
      (a, b) => a + b,
      0
    );
  },
  getStoryParts(id) {
    return WattpadV3Service.get(
      `stories/${id}?fields=parts(id,url,title,photoUrl),categories,language`
    ).then((data) => {
      return this.getStoryPartsContent(data.data.parts);
    });
  },
  timeOut(t, delayPromise) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, t);
    }).then(() => {
      return delayPromise;
    });
  },
  async getStoryPartsContent(parts) {
    var content = [];
    let time = 600;
    for (var p in parts) {
      content.push(await this.timeOut(
        time,
        this.getStoryPartContent(parts[p])
      ))
      time=time+1;
    }
    return content
  },
  async getStoryPartContent(part) {
    let content = await WattpadV2Service.get(`?m=storytext&id=${part.id}`);
    part.images = [];
    if (part.photoUrl) {
      part.images.push({
        chapter: part.url,
        img: part.photoUrl,
      });
    }
    part.content = sanitizeHtml(content.data, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
    });
    part.fullSanitizedContent = this.sanitizeAccent(part.content);
    part.content = part.content.replace(
      new RegExp(/(<[a-zA-Z]>|<\/[a-zA-Z]>|<br \/>)/gim),
      " $1 "
    );
    
    let contentForImg = _.clone(part.content);
    let myRegexp = /src="(.*?)"/g;
    var match = myRegexp.exec(contentForImg);
    while (match != null) {
      part.images.push({ chapter: part.url, img: match[1] });
      match = myRegexp.exec(contentForImg);
    }
    part.keywords = [];
    return part;
  },
  convertArrayStringToJson(string) {
    var str = string
      .replace(/array \(/g, "{")
      .replace(/\)/g, "}")
      .replace(/=>/g, ":")
      .replace(/\{\n\s*0 :/g, "[")
      .replace(/\n\s*\d* :/g, "")
      .replace(/,\n\s*},\n\s*},/g, "\n}\n],")
      .replace(/(, \d*|,\s*'[a-zA-Z0-9_.éèàê]*'),\n\s*}/g, "$1]")
      .replace(/\s*{\n\s*}/g, "[]")
      .replace(/\n*/g, "")
      .replace(/\s\s\s*/g, "")
      .replace(/,}/g, "}")
      .replace(/'/g, '"')
      .replace(/\\"/g, "'");
    return JSON.parse(str);
  },
  sanitizeAccent(str) {
    var map = {
      a: "á|à|ã|â|À|Á|Ã|Â",
      e: "é|è|ê|É|È|Ê",
      i: "í|ì|î|Í|Ì|Î",
      o: "ó|ò|ô|õ|Ó|Ò|Ô|Õ",
      u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
      c: "ç|Ç",
      n: "ñ|Ñ",
    };

    for (var pattern in map) {
      str = str.replace(new RegExp(map[pattern], "g"), pattern);
    }

    return str;
  },
};

export default StoryService;
