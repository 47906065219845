<script>
import moment from "moment";
import CategorySwitch from "./partials/CategorySwitch.vue";
import WattysForm from "./form/WattysForm.vue";

export default {
  components: {
    CategorySwitch,
    WattysForm,
  },
  props: {
    story: Object,
    sheetConfig: Object,
  },
  data() {
    return {};
  },
  methods: {
    updateCell(value, propertyName) {
      this.story[propertyName] = value;
      this.$emit("updateCell", this.story.story_id, value, propertyName);
    },
    async resolveStory(value) {
      this.updateCell(this.story["Additional Notes"], "Additional Notes");
      this.$emit("resolveStory", this.story.story_id, value);
    },
  },
  created() {
    if (this.story["Additional Notes"] == "") {
      let txt = `Title : ${this.story.wpStory.title}
    Publication date : ${moment(this.story.wpStory.createDate).format(
      "MM-DD-YYYY"
    )}`;
      this.story["Additional Notes"] = txt;
    }
    let fields = this.sheetConfig.columns.filter((i) => i.field && !i.category);
    this.form = [
      {
        title: "Initial judging",
        fields: fields,
      },
    ];
  },
};
</script>

<template>
  <div>
    <CategorySwitch :story="story" @updateCell="updateCell" />
    <h5 class="h5 mb-1">Logline</h5>
    <p class="font-weight-normal">
      {{ story.logline }}
    </p>
    <h5 class="h5 mb-1">Plot summary</h5>
    <p class="font-weight-normal">
      {{ story.plot_summary }}
    </p>
    <p class="font-weight-normal">
      by
      <a :href="'https://www.wattpad.com/user/' + story.user_name"
        ><strong>{{ story.user_name }}</strong></a
      >
      submitted at {{ story.date_submitted }}
    </p>
    <hr />
    <WattysForm
      :story="story"
      :sheetConfig="sheetConfig"
      :form="form"
      @updateCell="updateCell"
      @resolveStory="resolveStory"
    >
    </WattysForm>
  </div>
</template>