import _ from "underscore";
import { keywordsConf } from "@/conf/keywords.conf";
import GoogleSpreadsheetService from "./spreadsheet.service";

const KeywordsService = {
  baseURL: "",
  conf: {},
  excludeKeywords: ["fan-fiction", "random", "urban-fiction", "fanfiction"],
  init(baseURL) {
    this.baseURL = baseURL;
    this.conf = keywordsConf;
  },
  async get(title) {
    let  doc = await GoogleSpreadsheetService.init("1CjVg4FiaBc5eqxye2X1qcH780aoFAgKQvNHednPjSBk");
    let sheet = await doc.sheetsByTitle[title];
    return await sheet.getRows();
  },
  updateKeywords(lang) {
    localStorage.removeItem("keywords");
    return this.getKeywords(lang);
  },
  async syncKeywords(lang)
  {
    let  doc = await GoogleSpreadsheetService.init("1CjVg4FiaBc5eqxye2X1qcH780aoFAgKQvNHednPjSBk");
    let sheet = await doc.sheetsByTitle[lang.code.toUpperCase()];
    let rows = await sheet.getRows();
    let keywords = {};
    let collections = Object.getOwnPropertyNames(rows[0]).filter(item=>item[0]!="_");
    for (let r in rows)
    {
      for (let c in collections) {
        let collec = collections[c];
        if (!keywords[collec])
          keywords[collec]=[]
        if(rows[r][collec]) keywords[collec].push(rows[r][collec])
      }
    }
    localStorage.setItem("keywords_"+lang.code.toUpperCase(), JSON.stringify(keywords));
    return keywords;
  },
  async getKeywords(lang, forceRefresh = false) {
    if(!forceRefresh)
     {
      let storedKeyWords =  this.getStoredKeywords(lang);
      if(storedKeyWords)
        return storedKeyWords;
     }
    return  await this.syncKeywords(lang);
  },
  getStoredKeywords(lang) {
    if (localStorage.getItem("keywords_"+lang.code.toUpperCase()))
      return JSON.parse(localStorage.getItem("keywords_"+lang.code.toUpperCase()));
    else return false;
  },
  hasKeyWords: function(text, keywords) {
    for (var k in keywords) {
      if (text.indexOf(keywords[k]) > -1) return true;
    }
    return false;
  },
  populateStoriesParts(parts,keywordCollection)
  {
    for (var cat in keywordCollection) {
      var subcat = keywordCollection[cat].filter(
        (v) => !KeywordsService.excludeKeywords.includes(v)
      );
      //Trouver les mots clés dans le texte (et les compter)
      for (let p in parts) {
        let part = parts[p];
        if (!part.keywords[cat]) {
          part.keywords[cat] = [];
          part.keywords[cat].words = [];
        }
        part.keywords[cat].words = this.keyWordsCount(
          part.content,
          subcat
        );
        part.content = this.keyWordsHiglight(
          part.content,
          subcat,
          cat
        );
        parts[p] = part;
      }
    }
    return parts;
  },
  getWarning(story, keywordCollection) {
    let warning = [];
    for (var cat in keywordCollection) {
      var subcat = keywordCollection[cat].filter(
        (v) => !KeywordsService.excludeKeywords.includes(v)
      );
        if (
          this.hasKeyWords(
            story.description +
            story.tags.toString() +
            story.title.toLowerCase() +
            story.fulltxt,
            subcat
          )
        ) {
          warning.push(cat);
        }
    }
    return warning;
  },
  keyWordsCount:function(t,e)
  {
    var r=[];
    for(var a in e)
    {
      var s=e[a];
      s=s.replace(/(\*)/gi,"\\$1");
      var o="(\\W)("+s+")(\\W)",i=t.match(new RegExp(o,"gmi"));
      i&&(r[s]=i.length)
    }
    return r
  },
  keyWordsHiglight:function(t,e,r)
  {
    for(var a in e)
    {
      var s=e[a];
      s=s.replace(/(\*)/gi,"\\$1");
      var o='(?!")(\\W)(?!\\s)('+s+')(?<!\\s)(\\W)(?<!")';
      try{
        t=t.replace(new RegExp(o,"gmi"),'<mark class="'+r+'"> $2 </mark>')
      }
      catch(i){
        console.log(i)
      }}
      return t
  },
  char_convert: function(text) {
    var chars = [
      "©",
      "Û",
      "®",
      "ž",
      "Ü",
      "Ÿ",
      "Ý",
      "$",
      "Þ",
      "%",
      "¡",
      "ß",
      "¢",
      "à",
      "£",
      "á",
      "À",
      "¤",
      "â",
      "Á",
      "¥",
      "ã",
      "Â",
      "¦",
      "ä",
      "Ã",
      "§",
      "å",
      "Ä",
      "¨",
      "æ",
      "Å",
      "©",
      "ç",
      "Æ",
      "ª",
      "è",
      "Ç",
      "«",
      "é",
      "È",
      "¬",
      "ê",
      "É",
      "­",
      "ë",
      "Ê",
      "®",
      "ì",
      "Ë",
      "¯",
      "í",
      "Ì",
      "°",
      "î",
      "Í",
      "±",
      "ï",
      "Î",
      "²",
      "ð",
      "Ï",
      "³",
      "ñ",
      "Ð",
      "´",
      "ò",
      "Ñ",
      "µ",
      "ó",
      "Õ",
      "¶",
      "ô",
      "Ö",
      "·",
      "õ",
      "Ø",
      "¸",
      "ö",
      "Ù",
      "¹",
      "÷",
      "Ú",
      "º",
      "ø",
      "Û",
      "»",
      "ù",
      "Ü",
      "@",
      "¼",
      "ú",
      "Ý",
      "½",
      "û",
      "Þ",
      "€",
      "¾",
      "ü",
      "ß",
      "¿",
      "ý",
      "à",
      "‚",
      "À",
      "þ",
      "á",
      "ƒ",
      "Á",
      "ÿ",
      "å",
      "„",
      "Â",
      "æ",
      "…",
      "Ã",
      "ç",
      "†",
      "Ä",
      "è",
      "‡",
      "Å",
      "é",
      "ˆ",
      "Æ",
      "ê",
      "‰",
      "Ç",
      "ë",
      "Š",
      "È",
      "ì",
      "‹",
      "É",
      "í",
      "Œ",
      "Ê",
      "î",
      "Ë",
      "ï",
      "Ž",
      "Ì",
      "ð",
      "Í",
      "ñ",
      "Î",
      "ò",
      "‘",
      "Ï",
      "ó",
      "’",
      "Ð",
      "ô",
      "“",
      "Ñ",
      "õ",
      "”",
      "Ò",
      "ö",
      "•",
      "Ó",
      "ø",
      "–",
      "Ô",
      "ù",
      "—",
      "Õ",
      "ú",
      "˜",
      "Ö",
      "û",
      "™",
      "×",
      "ý",
      "š",
      "Ø",
      "þ",
      "›",
      "Ù",
      "ÿ",
      "œ",
      "Ú",
    ];
    var codes = [
      "&copy;",
      "&#219;",
      "&reg;",
      "&#158;",
      "&#220;",
      "&#159;",
      "&#221;",
      "&#36;",
      "&#222;",
      "&#37;",
      "&#161;",
      "&#223;",
      "&#162;",
      "&#224;",
      "&#163;",
      "&#225;",
      "&Agrave;",
      "&#164;",
      "&#226;",
      "&Aacute;",
      "&#165;",
      "&#227;",
      "&Acirc;",
      "&#166;",
      "&#228;",
      "&Atilde;",
      "&#167;",
      "&#229;",
      "&Auml;",
      "&#168;",
      "&#230;",
      "&Aring;",
      "&#169;",
      "&#231;",
      "&AElig;",
      "&#170;",
      "&#232;",
      "&Ccedil;",
      "&#171;",
      "&#233;",
      "&Egrave;",
      "&#172;",
      "&#234;",
      "&Eacute;",
      "&#173;",
      "&#235;",
      "&Ecirc;",
      "&#174;",
      "&#236;",
      "&Euml;",
      "&#175;",
      "&#237;",
      "&Igrave;",
      "&#176;",
      "&#238;",
      "&Iacute;",
      "&#177;",
      "&#239;",
      "&Icirc;",
      "&#178;",
      "&#240;",
      "&Iuml;",
      "&#179;",
      "&#241;",
      "&ETH;",
      "&#180;",
      "&#242;",
      "&Ntilde;",
      "&#181;",
      "&#243;",
      "&Otilde;",
      "&#182;",
      "&#244;",
      "&Ouml;",
      "&#183;",
      "&#245;",
      "&Oslash;",
      "&#184;",
      "&#246;",
      "&Ugrave;",
      "&#185;",
      "&#247;",
      "&Uacute;",
      "&#186;",
      "&#248;",
      "&Ucirc;",
      "&#187;",
      "&#249;",
      "&Uuml;",
      "&#64;",
      "&#188;",
      "&#250;",
      "&Yacute;",
      "&#189;",
      "&#251;",
      "&THORN;",
      "&#128;",
      "&#190;",
      "&#252",
      "&szlig;",
      "&#191;",
      "&#253;",
      "&agrave;",
      "&#130;",
      "&#192;",
      "&#254;",
      "&aacute;",
      "&#131;",
      "&#193;",
      "&#255;",
      "&aring;",
      "&#132;",
      "&#194;",
      "&aelig;",
      "&#133;",
      "&#195;",
      "&ccedil;",
      "&#134;",
      "&#196;",
      "&egrave;",
      "&#135;",
      "&#197;",
      "&eacute;",
      "&#136;",
      "&#198;",
      "&ecirc;",
      "&#137;",
      "&#199;",
      "&euml;",
      "&#138;",
      "&#200;",
      "&igrave;",
      "&#139;",
      "&#201;",
      "&iacute;",
      "&#140;",
      "&#202;",
      "&icirc;",
      "&#203;",
      "&iuml;",
      "&#142;",
      "&#204;",
      "&eth;",
      "&#205;",
      "&ntilde;",
      "&#206;",
      "&ograve;",
      "&#145;",
      "&#207;",
      "&oacute;",
      "&#146;",
      "&#208;",
      "&ocirc;",
      "&#147;",
      "&#209;",
      "&otilde;",
      "&#148;",
      "&#210;",
      "&ouml;",
      "&#149;",
      "&#211;",
      "&oslash;",
      "&#150;",
      "&#212;",
      "&ugrave;",
      "&#151;",
      "&#213;",
      "&uacute;",
      "&#152;",
      "&#214;",
      "&ucirc;",
      "&#153;",
      "&#215;",
      "&yacute;",
      "&#154;",
      "&#216;",
      "&thorn;",
      "&#155;",
      "&#217;",
      "&yuml;",
      "&#156;",
      "&#218;",
    ];
    var search = _.object(chars, codes);
    for (var s in search) {
      var re = new RegExp(s, "gm");
      text = text.replace(re, function myFunction(x) {
        if (search[x]) return search[x];
        else return x;
      });
    }
    return text;
  },
  sanitizeSpreadSheetDatas: function(entries) {
    var keywords = new Map();
    for (let col in entries) {
      var entry = entries[col];
      for (let key in entry) {
        if (key.indexOf("gsx$") > -1) {
          let value = this.char_convert(entry[key]["$t"].trim());
          key = key.replace("gsx$", "");
          if (value != "") {
            if (keywords.has(key)) {
              keywords.get(key).add(value);
            } else {
              let set = new Set().add(value);
              keywords.set(key, set);
            }
          }
        }
      }
    }
    var arrayK = {};
    for (let [key, value] of keywords) {
      arrayK[key] = Array.from(value);
    }
    return arrayK;
  },
};

export default KeywordsService;
