<template>
  <div class="container">
    <div class="row" style="margin-bottom: 30px">
      <div class="col-md-12">
        Which lang want you dive ?
        <mdb-dropdown slot="prepend">
          <mdb-dropdown-toggle
            color="primary"
            size="md"
            slot="toggle"
            class="z-depth-0"
          >
            <span v-if="selectedLang">{{ selectedLang.title }}</span>
            <span v-else>Language</span>
          </mdb-dropdown-toggle>
          <mdb-dropdown-menu class="displayoverflow">
            <mdb-dropdown-item
              v-model="selectedLang"
              v-on:click="
                selectedLang = lang;
                loadActivities();
              "
              v-for="lang in langConf"
              v-bind:key="lang.id"
              >{{ lang.title }}</mdb-dropdown-item
            >
            <mdb-dropdown-item
              v-model="selectedLang"
              v-on:click="
                selectedLang = { title: 'all' };
                loadActivities();
              "
              >All</mdb-dropdown-item
            >
          </mdb-dropdown-menu>
        </mdb-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div v-if="reports.length > 0">
          <h2>
            {{ reports.length }} awaiting reports in {{ selectedLang.title }}
          </h2>
          <mdb-list-group>
            <a
              :href="'/story/' + report.storyId"
              target="_blank"
              style="color: inherit"
              v-bind:key="index"
              v-for="(report, index) in reports"
            >
              <mdb-view hover style="cursor: pointer">
                <mdb-list-group-item
                  :icon="getIcon(report.activity)"
                  :iconColor="getColor(report.activity)"
                >
                  <div style="width: 100%">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-2 h6">{{ report.storyTitle }}</h5>
                      <small>{{ report.date | momentAgo }}</small>
                    </div>
                    <h6>
                      {{ report.activity | humanizeActivity }} by
                      {{ report.user }}
                    </h6>
                    <p
                      v-if="report.reasonToReport"
                      class="mb-2"
                      style="font-size: 0.8em"
                      v-html="linkdyReasonToReport(report.reasonToReport)"
                    ></p>
                  </div>
                  <mdb-mask flex-center overlay="blue-light" />
                </mdb-list-group-item>
              </mdb-view>
            </a>
          </mdb-list-group>
        </div>
        <div v-else>
          <p>Please select a language first</p>
        </div>
      </div>
      <div class="col-md-6">
        <h2>My recent activity</h2>
        <p>Last week activity ({{ lastActivities.length }})</p>
        <mdb-list-group>
          <mdb-list-group-item
            :icon="getIcon(activity.activity)"
            v-bind:key="index"
            v-for="(activity, index) in lastActivities"
          >
            {{ activity.date | date }} -
            <span v-if="activity.storyId">{{ activity.storyId }}</span>
            <span v-else>{{ activity.wpuser }}</span> -
            {{ activity.activity }}</mdb-list-group-item
          >
        </mdb-list-group>
      </div>
    </div>
  </div>
</template>
<script>
import { userService } from "../services/user.service";
import { actitiviesService } from "../services/activities.service";
//  import StoryService from "../services/story.service";
import { langConf } from "../conf/language.conf.js";
import {
  mdbListGroup,
  mdbListGroupItem,
  mdbView,
  mdbMask,
  mdbDropdownMenu,
  mdbDropdown,
  mdbDropdownToggle,
  mdbDropdownItem,
} from "mdbvue";
import moment from "moment";
import _ from "underscore";

export default {
  components: {
    mdbListGroup,
    mdbListGroupItem,
    mdbView,
    mdbMask,
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownItem,
    mdbDropdownToggle,
  },
  data() {
    return {
      reports: [],
      selectedLang: false,
      lastActivities: [],
      langConf: langConf,
    };
  },
  created() {
    this.loadRouteParams();
    actitiviesService
      .list(
        {
          user: userService.getUser().username,
          date: { $gte: Number(moment().subtract(7, "days").format("x")) },
        },
        { date: -1 }
      )
      .then((response) => {
        this.lastActivities = response;
      });
  },
  filters: {
    momentAgo(date) {
      return moment(date).fromNow();
    },
    humanizeActivity(activity) {
      return (
        activity.charAt(0).toUpperCase() +
        activity.split("-").join(" ").slice(1)
      );
    },
    date(date) {
      return moment(date).format("MM-DD-YYYY");
    },
  },
  methods: {
    loadRouteParams() {
      if (this.$route.params.lang)
        this.selectedLang = _.findWhere(langConf, {
          code: this.$route.params.lang.id,
        });
      else
        this.selectedLang = localStorage.lang
          ? JSON.parse(localStorage.lang)
          : null;
      if (this.selectedLang != null) this.loadActivities();
    },

    getIcon(activity) {
      return actitiviesService.actionsList[activity]
        ? actitiviesService.actionsList[activity].icon
        : "user";
    },
    getColor(activity) {
      return actitiviesService.actionsList[activity]
        ? actitiviesService.actionsList[activity].color
        : "user";
    },
    linkdyReasonToReport(s) {
      let details = s.split(" | ");
      let reason = "";
      for (let d in details) {
        reason = d != 0 ? reason + "<br />" : "";
        let urlRegex = /(https?:\/\/[^ ]*)/;
        reason =
          reason +
          details[d].replace(
            urlRegex,
            `<a href="$1" target="_blank">see link</a>`
          );
      }
      return reason;
    },
    timeOut(t, delayPromise) {
      return new Promise((resolve) => {
        setTimeout(() => {
          console.log(t);
          resolve(true);
        }, t);
      }).then(() => {
        return delayPromise;
      });
    },
    loadActivities() {
      this.reports = [];
      let role = userService.getUser().role;
      let reportFilter = [];
      for (let a in actitiviesService.actionsList) {
        if (
          actitiviesService.actionsList[a].diverAssignement &&
          actitiviesService.actionsList[a].diverAssignement.indexOf(role) > -1
        )
          reportFilter.push(actitiviesService.actionsList[a].id);
      }
      reportFilter = _.unique(reportFilter);
      //let listParams={wpuser:{$exists:false}};
      let listParams =
        this.selectedLang.title != "all"
          ? {
              storylang: this.selectedLang.id,
              activity: { $in: reportFilter },
              expired: { $ne: true },
            }
          : { activity: { $in: reportFilter }, expired: { $ne: true } };
      actitiviesService.list(listParams).then((reportsData) => {
        this.reports = reportsData;
      });
    },
  },
};
</script>