<template>
    <mdb-row>
      <mdb-col md="4" offsetMd="4">
        <form>
          <h1 class="mt-5">Sign in</h1>
          <div class="grey-text">
            <mdb-input label="Username" icon="user" v-model="username"/>
            <mdb-input label="Password" icon="lock" type="password" v-model="password"/>
          </div>
          <div class="text-center">
            <mdb-btn v-on:click="login()">Login</mdb-btn>
          </div>
        </form>
      </mdb-col>
    </mdb-row>
</template>

<script>
   import { 
    mdbInput, 
    mdbBtn,
    mdbCol,
    mdbRow } from 'mdbvue';
  import { userService } from '../services/user.service.js';

export default {
  name: 'Login',
  components: {
    mdbInput,
    mdbBtn,
    mdbCol,
    mdbRow
  },
  methods:{
    login(){
      userService.login(this.username,this.password)
    }
  },
  data () {
    return {
      username:"",
      password:""
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}
</style>
