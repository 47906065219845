<template>
  <div class="flexible-content">
    <vue-confirm-dialog></vue-confirm-dialog>
    <!--Navbar-->
    <mdb-navbar
      class="flexible-navbar white"
      light
      position="top"
      scrolling
      :class="{ paddedleft: sideBarColl }"
    >
      <mdb-navbar-nav left id="toogleSideBar" v-if="!displaySidebar">
        <mdb-nav-item>
          <a v-on:click="toggleSideBar()">
            <mdb-icon icon="angle-double-right"></mdb-icon
          ></a>
        </mdb-nav-item>
      </mdb-navbar-nav>
      <mdb-navbar-toggler right>
        <mdb-navbar-nav right>
          <mdb-nav-item waves-fixed
            ><a href=" https://goo.gl/ivFVBa" target="_blank"
              >Internales Guidelines</a
            ></mdb-nav-item
          >
          <mdb-nav-item waves-fixed>
            <a
              href="https://docs.google.com/document/d/1r-czWh02IwIJL0RQExITpRkhTS95LfrGwg-LBua37HY/edit"
              target="_blank"
              >Content Doc</a
            >
          </mdb-nav-item>
          <mdb-nav-item waves-fixed
            ><a href="https://goo.gl/Pbsuti" target="_blank">
              Notes on internal guidelines</a
            ></mdb-nav-item
          >
          <mdb-nav-item waves-fixed
            ><a
              href="https://docs.google.com/spreadsheets/d/1Z1dLO3m7b6ZzJmJYPHYdHRSTrxrM4yxiaWX3h4XqWJs/edit?usp=sharing"
              target="_blank"
              >TagList</a
            ></mdb-nav-item
          >
          <mdb-nav-item waves-fixed
            ><a
              href="https://docs.google.com/spreadsheets/d/1CjVg4FiaBc5eqxye2X1qcH780aoFAgKQvNHednPjSBk/edit#gid=1537650288"
              target="_blank"
              >Keywords</a
            >
            <!-- <animated-rotate-in v-if="refreshKW">
               <mdb-icon icon="sync"></mdb-icon
          > spin
            </animated-rotate-in> 
            <a v-else  @click="refreshingKW()"> <mdb-icon icon="sync"></mdb-icon
          ></a> -->
            </mdb-nav-item
          >
        </mdb-navbar-nav>

        <mdb-navbar-nav>
          <mdb-dropdown tag="li" class="nav-item">
            <mdb-dropdown-toggle tag="a" navLink slot="toggle" waves-fixed
              >Banned cover reminder</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu style="width: 300px">
              <mdb-dropdown-item>
                <div style="white-space: initial !important">
                  <p>
                    Media on Wattpad includes images, videos, gifs, and sound
                    clips. The following Media is not allowed on Wattpad, and
                    will be removed:
                  </p>
                  <ul class="text-break">
                    <li>
                      Media containing full exposure of any private parts, such
                      as genitalia, breasts and buttocks.
                    </li>
                    <li>
                      Media displaying sexual intercourse, or any other sexual
                      act, regardless of whether private parts are visible.
                    </li>
                    <li>Media displaying self-harm or suicide</li>
                    <li>
                      Images of people posted without their consent, except for
                      public figures and celebrities.
                    </li>
                    <li>Any other media which captures an illegal act.</li>
                  </ul>
                </div>
              </mdb-dropdown-item>
            </mdb-dropdown-menu>
          </mdb-dropdown>
        </mdb-navbar-nav>
        <mdb-navbar-nav right>
          <mdb-nav-item waves-fixed right>
            <a v-on:click="logout()"><mdb-icon icon="power-off"></mdb-icon></a>
          </mdb-nav-item>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>
    <!--/.Navbar-->
    <!-- Sidebar -->
    <div v-if="displaySidebar" class="sidebar-fixed position-fixed">
      <a
        v-if="sideBarColl"
        v-on:click="toggleSideBar()"
        class="float-left mt-4"
      >
        <mdb-icon icon="angle-double-left"></mdb-icon
      ></a>
      <h1>Wattpad Content Ambassadors</h1>
      <img class="logo img-fluid" src="../assets/logo.png" />
      <p id="intro" class="text-center">
        <router-link
          :to="'/ambassador/' + user.username"
          @click.native="activeItem = 2"
        >
          <mdb-icon icon="user-ninja" class="mr-2" />Hi {{ user.username }} !
        </router-link>
      </p>
      <mdb-list-group class="list-group-flush">
        <router-link to="/doc" @click.native="activeItem = 3">
          <mdb-list-group-item
            :action="true"
            :class="activeItem === 3 && 'active'"
            ><mdb-icon
              icon="atlas"
              class="mr-3"
            />Documentation</mdb-list-group-item
          >
        </router-link>
        <router-link to="/hotlist" @click.native="activeItem = 1">
          <mdb-list-group-item
            :action="true"
            :class="activeItem === 1 && 'active'"
            ><mdb-icon icon="hammer" class="mr-3" />Hotlist
            moderation</mdb-list-group-item
          >
        </router-link>
        <router-link
          v-if="roleIsDiver()"
          to="/diverhole"
          @click.native="activeItem = 6"
        >
          <mdb-list-group-item
            :action="true"
            :class="activeItem === 6 && 'active'"
            ><mdb-icon icon="gavel" class="mr-3" />Diver
            hole</mdb-list-group-item
          >
        </router-link>
        <router-link
          v-if="user.isWattysJudge"
          to="/wattys"
          @click.native="activeItem = 7"
        >
          <mdb-list-group-item
            :action="true"
            :class="activeItem === 7 && 'active'"
            ><mdb-icon icon="balance-scale" class="mr-3" />Wattys
            corner</mdb-list-group-item
          >
        </router-link>
        <mdb-list-group-item
          :action="true"
          v-if="!roleIs('none') && !roleIs('other-team')"
        >
          <mdb-dropdown style="min-width: 200px" multiLevel dropright>
            <span slot="toggle"
              ><mdb-icon icon="star" class="mr-3" />Bookmarks</span
            >
            <mdb-dropdown-menu class="pr-2">
              <mdb-treeview
                style="width: 110%"
                header="Bookmarks"
                class="tree"
                v-for="(group, index) in bookmarks"
                v-bind:key="index"
              >
                <span
                  v-for="(b, i) in group"
                  v-bind:key="i"
                  style="position: relative; width: auto; display: block"
                >
                  <mdb-treeview-item
                    tag="a"
                    :href="'/' + b.bookmark"
                    :icon="bookmarkIcon(index)"
                    :title="b.bookmark.replace('hotlist/', '')"
                    @click.native="activeItem = 3"
                  />
                  <mdb-icon
                    far
                    icon="times-circle"
                    class="position-absolute float-right"
                    @click.native="removeBookMark(b.bookmark, b.type, i)"
                    style="top: 6px; right: 0"
                  />
                </span>
              </mdb-treeview>
            </mdb-dropdown-menu>
          </mdb-dropdown>
        </mdb-list-group-item>
      </mdb-list-group>
      <mdb-card class="mt-2 mb-2">
        <mdb-card-header>
          <mdb-icon icon="map" class="mr-3" />Quick search
        </mdb-card-header>
        <mdb-card-body>
          <!-- Search form -->
          <div class="md-form mt-3">
            <input
              class="form-control"
              type="text"
              placeholder="Fiction's ID"
              aria-label="Fiction's ID"
              v-model="quickFictionId"
            />
          </div>
          <a
            v-if="quickFictionId != null"
            type="button"
            class="btn btn-outline-primary waves-effect"
            :href="'/story/' + quickFictionId"
            >Search!</a
          >
          <div class="md-form mt-3">
            <input
              class="form-control"
              type="text"
              placeholder="User's username"
              aria-label="User's username"
              v-model="quickUser"
            />
          </div>
          <a
            v-if="quickUser != null"
            type="button"
            class="btn btn-outline-primary waves-effect"
            :href="'/user/' + quickUser"
            >Search!</a
          >
        </mdb-card-body>
      </mdb-card>
      <!-- <router-link  to="/statistics" @click.native="activeItem = 7">
          <mdb-list-group-item
            :action="true"
            :class="activeItem === 7 && 'active'"
            ><mdb-icon icon="chart-pie" class="mr-3" />Statistics</mdb-list-group-item
          >
        </router-link> -->
    </div>
    <!-- /Sidebar  -->
    <main :class="{ paddedleft: sideBarColl }">
      <div class="mt-5 pt-5">
        <router-view></router-view>
      </div>
      <ftr color="primary-color-dark" class="text-center font-small darken-2">
        <p class="footer-copyright mb-0 py-3 text-center">
          Provided by
          <a href="https://www.wattpad.com/user/mesdamesduc" target="_blank"
            >mesdamesduc</a
          >
          - Icon made by Freepik from www.flaticon.com
        </p>
      </ftr>
    </main>
  </div>
</template>

<script>
import { userService } from "../services/user.service.js";
import { bookmarkService } from "../services/bookmarks.service.js";
import { langConf } from "../conf/language.conf.js";

import  KeywordsService from "../services/keywords.service.js";

import {
  mdbNavbar,
  mdbNavItem,
  mdbNavbarNav,
  mdbNavbarToggler,
  mdbIcon,
  mdbListGroup,
  mdbListGroupItem,
  mdbFooter,
  mdbDropdown,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbDropdownItem,
  waves,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbTreeview,
  mdbTreeviewItem,
} from "mdbvue";

export default {
  name: "PrivateLayout",
  components: {
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbListGroup,
    mdbListGroupItem,
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbDropdownItem,
    mdbIcon,
    ftr: mdbFooter,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbTreeview,
    mdbTreeviewItem,
  },
  data() {
    return {
      activeItem: 1,
      quickFictionId: null,
      bookmarks: [],
      quickUser: null,
      displaySidebar: true,
      sideBarColl: false,
      role: false,
      user: false,
      refreshKW:false
    };
  },
  created() {
    this.windsizeHandler();
    window.addEventListener("resize", this.windsizeHandler);
    this.getBookmarks();
    this.getUser();
    this.role = this.user.role;
  },
  destroyed() {
    window.removeEventListener("resize", this.windsizeHandler);
  },
  methods: {
    async refreshingKW(){
      console.log("refreshkw")
      this.refrehKW =true;
      for(let c in langConf)
      {
        let lang = langConf[c];
        await KeywordsService.syncKeywords(lang, true);
      }
      this.refreshKW=false;
    },
    getUser() {
      this.user = userService.getUser();
      return this.user;
    },
    logout() {
      userService.logout();
    },
    getBookmarks() {
      this.$root.bookmarks = userService.getUser().bookmarks;
      this.bookmarks = this.$root.bookmarks;
    },
    windsizeHandler() {
      if (window.innerWidth < 1200) {
        this.displaySidebar = false;
        this.sideBarColl = false;
      } else {
        this.displaySidebar = true;
        this.sideBarColl = false;
      }
    },
    roleIs(role) {
      return this.role.indexOf(role) > -1;
    },
    roleIsDiver() {
      return this.role.toString().indexOf("diver") > -1;
    },
    toggleSideBar() {
      if (window.innerWidth < 1200) {
        this.displaySidebar = !this.displaySidebar;
        this.sideBarColl = !this.sideBarColl;
      } else {
        this.displaySidebar = true;
        this.sideBarColl = false;
      }
    },
    bookmarkIcon(index) {
      switch (index) {
        case "list":
          return "grip-vertical";
        case "story":
          return "book";
        case "user":
          return "user";
        default:
          return "bookmark";
      }
    },
    displayPadding() {
      return this.displaySidebar ? "270px" : "10px";
    },
    removeBookMark(bookmark, grouptype, groupIndex) {
      console.log(grouptype, bookmark);
      bookmarkService
        .findOne({ bookmark: bookmark, user: this.user.username })
        .then((response) => {
          bookmarkService.deleteBookmarks(response._id.$oid);
          this.bookmarks[grouptype].splice(groupIndex, 1);
          userService.updateBookMarks(this.bookmarks);
        })
        .catch(() => {
          console.log(this.bookmarks[grouptype]);
          this.bookmarks[grouptype].splice(groupIndex, 1);
          userService.updateBookMarks(this.bookmarks);
        });
    },
  },
  beforeMount() {
    this.activeItem = this.$route.matched[0].props.default
      ? this.$route.matched[0].props.default.page
      : 3;
  },
  mixins: [waves],
  watch: {
    $route() {
      this.windsizeHandler();
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
.navbar-light .navbar-brand {
  margin-left: 15px;
  color: #2196f3 !important;
  font-weight: bolder;
}
.btn-icon {
  padding: 0.5rem 0.8rem !important;
}

.tree h6,
.tree hr {
  display: none;
}
.border.tree {
  border: none !important;
  margin: 0;
  padding-right: 1.5em;
}
</style>

<style scoped>
h1 {
  font-size: 1.2em;
  text-align: center;
  margin-top: 5%;
}
main {
  background-color: #ededee;
}

.sidebar-fixed img.logo {
  padding: 1.5rem;
  width: 80%;
}
#intro {
  border-bottom: 1px #eee solid;
  padding-bottom: 15px;
}
.flexible-content {
  transition: padding-left 0.3s;
  padding-left: 270px;
}

.flexible-navbar {
  transition: padding-left 0.5s;
  padding-left: 270px;
}

.sidebar-fixed {
  left: 0;
  top: 0;
  height: 100vh;
  width: 270px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1050;
  background-color: #fff;
  padding: 1.5rem;
  padding-top: 0;
}

.sidebar-fixed .list-group-item {
  display: block !important;
  transition: background-color 0.3s;
}

.sidebar-fixed .list-group .active {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: none;
  }
  .flexible-content {
    padding-left: 0;
  }
  .flexible-navbar {
    padding-left: 10px;
  }
}
@media (max-height: 654px) {
  img.logo {
    max-height: 54px;
    max-width: 54px;
    padding: 0 !important;
    margin: auto;
    display: block;
    padding: 0 !important;
  }
  .sidebar-fixed .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sidebar-fixed .card-body .md-form {
    margin-bottom: 3px;
    margin-top: 3px !important;
  }
  .sidebar-fixed .card-body .btn {
    padding: 0;
  }
  .sidebar-fixed .list-group-item {
    font-size: 0.9em;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.paddedleft.flexible-content,
.paddedleft.flexible-navbar,
main.paddedleft {
  padding-left: 270px !important;
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: block !important;
  }
}

@media (max-width: 1399px) {
  .nav-item {
    font-size: 0.9em;
  }
}
</style>
