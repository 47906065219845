<script>
import {
  mdbRow,
  mdbCol,
  mdbListGroup,
  mdbListGroupItem,
  mdbBadge,
  mdbBtn,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbCard,
  mdbCardTitle,
  mdbCardText,
} from "mdbvue";
import ProgressBar from "vue-simple-progress";

export default {
  components: {
    mdbRow,
    mdbCol,
    mdbListGroup,
    mdbListGroupItem,
    mdbBadge,
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    ProgressBar,
    mdbCard,
    mdbCardTitle,
    mdbCardText,
  },
  props: {
    stats: Array,
    step: String,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    classment() {
      return this.stats.find((i) => i.classment)
        ? this.stats.find((i) => i.classment).classment
        : false;
    },
    statistics() {
      return this.stats.slice(0, this.stats.length - 1);
    },
    awardsStats() {
      return this.stats.slice(-1)[0];
    },
    awards() {
      let statsAward = this.stats.slice(-1)[0];
      return statsAward ? Object.keys(statsAward.fanfiction) : false;
    },
  },
  methods: {
    updateStats() {
      this.show = !this.show;
      console.log("Update Stats");
      if (this.show) this.$emit("updateStats");
    },
  },
};
</script>

<template>
  <div>
    <mdb-btn primary @click="updateStats()"> {{ step }} Statistics </mdb-btn>
    <mdb-row v-if="show">
      <mdb-col md="4" v-for="(stat, k) in statistics" v-bind:key="k">
        <mdb-card class="card-body">
          <mdb-card-title>{{ stat.title }}</mdb-card-title>
          <mdb-card-text>
            <mdb-list-group flush>
              <mdb-list-group-item
                v-for="(value, key) in stat.data"
                v-bind:key="key"
              >
                {{ value.name
                }}<mdb-badge color="primary" pill>{{ value.value }}</mdb-badge>
              </mdb-list-group-item>
            </mdb-list-group>
            <progress-bar
              :val="stat.progress"
              :text="stat.progress + '% '"
            ></progress-bar>
          </mdb-card-text>
        </mdb-card>
      </mdb-col>
      <mdb-col md="4" v-if="classment">
        <mdb-card class="card-body">
          <mdb-card-title>Classment</mdb-card-title>
          <mdb-card-text>
            <ol>
              <li v-for="(value, key) in classment" v-bind:key="key">
                {{ value.judge }} ({{ value.doneCount }})
              </li>
            </ol>
          </mdb-card-text>
        </mdb-card>
      </mdb-col>
    </mdb-row>
    <mdb-row v-if="show" class="mt-4">
      <mdb-card class="card-body p-4">
        <mdb-card-title>Awards Statistics</mdb-card-title>
        <mdb-card-text>
          <mdb-tbl sm>
            <mdb-tbl-head>
              <tr>
                <th>Award</th>
                <th v-for="award in awards" v-bind:key="award">{{ award }}</th>
              </tr>
            </mdb-tbl-head>
            <mdb-tbl-body>
              <tr v-for="(award, i) in awardsStats" v-bind:key="i">
                <th>{{ i }}</th>
                <td>{{ award.total }}</td>
                <td>{{ award["to the next list"] }}</td>
                <td>{{ award.done }}</td>
                <td>{{ award.left }}</td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
        </mdb-card-text>
      </mdb-card>
    </mdb-row>
  </div>
</template>