<script>
import WattysForm from "./form/WattysForm.vue";
import { wattysConf } from "../../conf/wattys.conf";
import { mdbListGroup, mdbListGroupItem, mdbBadge } from "mdbvue";
export default {
  components: {
    WattysForm,
    mdbListGroupItem,
    mdbListGroup,
    mdbBadge,
  },
  props: {
    story: Object,
    sheetConfig: Object,
  },
  data() {
    return {};
  },
  methods: {
    updateCell(value, propertyName) {
      this.story[propertyName] = value;
      this.$emit("updateCell", this.story.story_id, value, propertyName);
    },
    async resolveStory(value) {
      this.$emit("resolveStory", this.story.story_id, value);
    },
    async markAsDone() {
      this.$emit("updateCell", this.story.story_id, "Yes", "Judging Complete?");
      this.$emit("markAsDone", this.story.story_id);
    },
  },
  computed: {
    previous_judging() {
      let prevConf = wattysConf.steps
        .filter((i) => i.name == "LongList")[0]
        .columns.filter((i) => i.field)
        .map((i) => i.propertyName);
      let informativFields = this.sheetConfig.columns
        .filter((col) => !col.field)
        .map((k) => k.propertyName);
      return prevConf.filter((p) => informativFields.includes(p));
    },
  },
  created() {
    let fields = this.sheetConfig.columns.filter((i) => i.field);
    this.form = [
      {
        title: "Longlist Review",
        fields: [fields[0]],
      },
      {
        title: "Final judging",
        fields: fields.slice(1, fields.length),
      },
    ];
  },
};
</script>

<template>
  <div>
    <p>
      <strong>STEP 1: </strong> Read carrefully the all story on Wattpad, or
      with the full analisys tool.
    </p>
    <p>
      <a
        class="btn btn-warning"
        :href="'https://www.wattpad.com/story/' + story.story_id"
        target="_blank"
        color="warning"
        >Open on Wattpad</a
      ><a
        class="btn btn-primary"
        :href="'/story/' + story.story_id"
        target="_blank"
        >Full analisys</a
      >
    </p>
    <p>
      <strong>Writer Confirmed Shortlist Status : </strong>
      <span v-if="story['Writer Confirmed Shortlist Status'] == 'TRUE'">
        ✔️ Confirmed</span
      >
      <span v-else>❔Unconfirmed</span>
    </p>
    <p>
      <strong>Award category : </strong>
      {{ story["award_category"] }}
    </p>
    <hr class="mb-4" />
    <p><strong>STEP 2:</strong> Final individual judging</p>

    <WattysForm
      :story="story"
      :sheetConfig="sheetConfig"
      :form="form"
      @updateCell="updateCell"
      @resolveStory="resolveStory"
      @markAsDone="markAsDone"
    >
      <template v-slot:llr>
        <mdb-list-group>
          <span class="text-right" v-if="story['Previous Judge']">
            Previous judge : {{ story["Previous Judge"] }}
          </span>
          <mdb-list-group-item v-for="k in previous_judging" v-bind:key="k"
            >{{ k
            }}<mdb-badge color="primary" pill>{{ story[k] }} / 4</mdb-badge>
          </mdb-list-group-item>
        </mdb-list-group>
      </template>
    </WattysForm>
  </div>
</template>