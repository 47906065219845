const wattysConf = {
    award_categories: [
        "fanfiction",
        "fantasy",
        "historical_fiction",
        "horror",
        "mystery_thriller",
        "new_adult",
        "paranormal",
        "romance",
        "science_fiction",
        "young_adult",
        "wild_card",
    ],
    steps: [
        {
            name:"Initial",
            validationKey:"From what you know about this story, is it well written and compelling enough for you to want to continue reading?",   
            notesKey:"Additional Notes",
            range : "A:U",
            index:0,
            columns:[
        {  label:
            "Assigned Judge",
        propertyName:"Assigned Judge",
        column:"N"},
        {  label:
            "Priority",
        propertyName:"Priority",
        column:"O"},
        {  label:
            "Confirm Category",
            propertyName: "Confirm Category",
        column:"P"},
        {  label:
            "From what you know about this story, is it well written and compelling enough for you to want to continue reading?",
            propertyName: "From what you know about this story, is it well written and compelling enough for you to want to continue reading?",
        column:"Q"},
        {  label:
            "Additional Notes",
            propertyName: "Additional Notes",
            field: "FreeText",
            notrequired:true,
        column:"R"},
        {  label:
            "'Judging Complete?",
        propertyName:"'Judging Complete?",
        column:"S"},
    ]}, 
    {
        name:"LongList",
        range:"A:AR",
        validationKey:"From what you know about this story, are you compelled to continue reading?",
        notesKey:"Notes on why this story should or should not move on to the shortlist?",
        index:1,
        columns:[
        {  label:
            "Assigned Judge",
        propertyName:"Assigned Judge",
        column:"N"},
        {  label:
            "Confirm Category",
        propertyName:"Confirm Category",
        column:"O"},
        {
            label:
            "The concept is compelling, offering a refreshing take on familiar ideas or an intriguing new idea.	",
            field: "Slider",
            column: "P",
            propertyName:"The concept is compelling, offering a refreshing take on familiar ideas or an intriguing new idea."
        },
        {
            label:
                "The main character is multi-dimensional, with compelling character development throughout the story.",
            field: "Slider",
            column: "Q",
            propertyName:"The main character is multi-dimensional, with compelling character development throughout the story."
        },
        {
            label:
                "There is no major info-dumping, and the writing shows an appropriate balance of narration and exposition.",
            field: "Slider",
            column: "R",
            propertyName:"There is no major info-dumping, and the writing shows an appropriate balance of narration and exposition."
        },
        {
            label:
                "Dialogue is memorably mixed with narration and is not overused.",
            field: "Slider",
            column: "S",
            propertyName:"Dialogue is memorably mixed with narration and is not overused."
        },
        {
            label:
                "The story events are easy to follow and are paced in a way that keeps the reader hooked.",
            field: "Slider",
            column: "T",
            propertyName:"The story events are easy to follow and are paced in a way that keeps the reader hooked."
        },
        {
            label: "Punctuation, spelling, and grammar are mostly correct.",
            field: "Slider",
            column: "U",
            propertyName:"Punctuation, spelling, and grammar are mostly correct."
        },
        {
            label: "Does this story have any content flags?",
            field: "DropDown",
            column: "V",
            propertyName:"Does this story have any content flags?"
        },
        {
            label:
                "From what you know about this story, are you compelled to continue reading?",
            field: "DropDown",
            column: "W",
            propertyName:"From what you know about this story, are you compelled to continue reading?"
        },

        {
            label:
                "The story defies expectations, transcends multiple genres and offer readers something completely",
            field: "Slider",
            category: "wild_card",
            column: "X",
            propertyName: "Wild Card The story defies expectations, transcends multiple genres and offer readers something completely",
        },
        {
            label:
                "The fandom/characters are developed thoroughly, giving readers outside of the fandom enough context to follow along",
            field: "Slider",
            category: "fanfiction",
            column: "Y",
            propertyName:"Fanfiction The fandom/characters are developed thoroughly, giving readers outside of the fandom enough context to follow along",

        },
        {
            label:
                "Follows typical narrative beats of a story, and is not a collection of several different stories",
            field: "Slider",
            category: "fanfiction",
            column: "Z",
            propertyName:"Fanfiction Follows typical narrative beats of a story, and is not a collection of several different stories",
        },
        {
            label:
                "The setting is a world different from our own, and the world-building is interwoven with the story with no major info-dumping",
            field: "Slider",
            category: "fantasy",
            column: "AA",
            propertyName:"Fantasy The setting is a world different from our own, and the world-building is interwoven with the story with no major info-dumping",
 
        },
        {
            label:
                "The story shows a fresh take on a fantasy world with an exciting hook",
            field: "Slider",
            category: "fantasy",
            column: "AB",
            propertyName: "Fantasy The story shows a fresh take on a fantasy world with an exciting hook",

        },
        {
            label:
                "The author shows that they have a good grasp on the historical period where the story is set, and that research was done to get it right",
            field: "Slider",
            category: "historical_fiction",
            column: "AC",
            propertyName:"Historical Fiction The author shows that they have a good grasp on the historical period where the story is set, and that research was done to get it right",

        },
        {
            label:
                " The character's interpersonal stakes are balanced expertly with the external conflicts of the plot",
            field: "Slider",
            category: "historical_fiction",
            column: "AD",
            propertyName:"Historical Fiction The character's interpersonal stakes are balanced expertly with the external conflicts of the plot",

        },
        {
            label:
                " The stakes, conflict, and tension in the story are high, gripping the reader with a horrifying concept",
            field: "Slider",
            category: "horror",
            column: "AE",
            propertyName:"Horror The stakes, conflict, and tension in the story are high, gripping the reader with a horrifying concept",

        },
        {
            label:
                "The characters are fleshed out and unique, experience trouble and distress, and make the reader care about them",
            field: "Slider",
            category: "horror",
            column: "AF",
            propertyName:"The characters are fleshed out and unique, experience trouble and distress, and make the reader care about them",

        },
        {
            label:
                "The mystery/thriller aspect is at the core of the plot, gripping the reader as believable, with surprising twists and a pace that builds suspense",
            field: "Slider",
            category: "mystery_thriller",
            column: "AG",
            propertyName:"Mystery/Thriller The mystery/thriller aspect is at the core of the plot, gripping the reader as believable, with surprising twists and a pace that builds suspense",
        },
        {
            label:
                "The main characters are developed and multi-dimensional, with clear arcs throughout the story that relate to them stepping into the beginnings of adult life",
            field: "Slider",
            category: "new_adult",
            column: "AH",
            propertyName:"New Adult The main characters are developed and multi-dimensional, with clear arcs throughout the story that relate to them stepping into the beginnings of adult life",
        },
        {
            label:
                "The paranormal elements of the story occur in a world similar to our own, and are fresh and compelling",
            field: "Slider",
            category: "paranormal",
            column: "AI",
            propertyName:"Paranormal The paranormal elements of the story occur in a world similar to our own, and are fresh and compelling",

        },
        {
            label:
                "The main characters are developed and multi-dimensional (both in and out of the relationship) and have clear arcs throughout the story.",
            field: "Slider",
            category: "romance",
            column: "AJ",
            propertyName:"Romance The main characters are developed and multi-dimensional (both in and out of the relationship) and have clear arcs throughout the story.",

        },
        {
            label:
                "The romance takes the reader on an emotional journey that compels them to ship the main characters and hope for a happy ending",
            field: "Slider",
            category: "romance",
            column: "AK",
            propertyName:"Romance The romance takes the reader on an emotional journey that compels them to ship the main characters and hope for a happy ending",

        },
        {
            label:
                "The character's interpersonal stakes are balanced expertly with the external conflicts of the plot",
            field: "Slider",
            category: "science_fiction",
            column: "AL",
            propertyName:"Science Fiction The character's interpersonal stakes are balanced expertly with the external conflicts of the plot",

        },
        {
            label:
                "The story shows a fresh take on science fiction with an exciting hook. ",
            field: "Slider",
            category: "science_fiction",
            column: "AM",
            propertyName:"Science Fiction The story shows a fresh take on science fiction with an exciting hook. ",

        },
        {
            label:
                "The main characters are developed and multi-dimensional, with clear arcs throughout the story that showcase them discovering who they are and what they want",
            field: "Slider",
            category: "young_adult",
            column: "AN",
            propertyName:"Young Adult The main characters are developed and multi-dimensional, with clear arcs throughout the story that showcase them discovering who they are and what they want",

        },
        {
            label:
                "The voice comes through as genuinely teen, remaining authentic and current",
            field: "Slider",
            category: "young_adult",
            column: "AO",
            propertyName: "Young Adult The voice comes through as genuinely teen, remaining authentic and current",

        },
        {
            label:
                "Notes on why this story should or should not move on to the shortlist?",
            field: "FreeText",
            column: "AP",
            propertyName:"Notes on why this story should or should not move on to the shortlist?"
        },
    ]},
    {
        name: "ShortList",
        index:2,
        range:"A:AH",
        columns:[
            {  
                label:"Writer Confirmed Shortlist Status",
                propertyName:"Writer Confirmed Shortlist Status",
                column:"N"
            },
            {  
                label:"Assigned Judge",
                propertyName:"Assigned Judge",
                column:"O"
            },
            {  
                label:"Previous Judge",
                propertyName:"Previous Judge",
                column:"AG"
            },
            {  
                label:"The concept is compelling, offering a refreshing take on familiar ideas or an intriguing new idea.",
                propertyName:"The concept is compelling, offering a refreshing take on familiar ideas or an intriguing new idea.",
                column:"P",
            },
            {  
                label:"The main character is multi-dimensional, with compelling character development throughout the story.",
                propertyName:"The main character is multi-dimensional, with compelling character development throughout the story.",
                column:"Q",
            },
            {  
                label:"There is no major info-dumping, and the writing shows an appropriate balance of narration and exposition.",
                propertyName:"There is no major info-dumping, and the writing shows an appropriate balance of narration and exposition.",
                column:"R",
            },
            {  
                label:"Dialogue is memorably mixed with narration and is not overused.",
                propertyName:"Dialogue is memorably mixed with narration and is not overused.",
                column:"S",
            },
            {  
                label:"The story events are easy to follow and are paced in a way that keeps the reader hooked.",
                propertyName:"The story events are easy to follow and are paced in a way that keeps the reader hooked.",
                column:"T",
            },
            {  
                label:"Punctuation, spelling, and grammar are mostly correct.",
                propertyName:"Punctuation, spelling, and grammar are mostly correct.",
                column:"U",
            },
            {  
                label:"Is the Longlist Assessment Accurate?",
                propertyName:"Is the Longlist Assessment Accurate?",
                column:"V",
                field: "DropDown",
            },
            {  
                label:"Flags? (Confirm)",
                propertyName:"Flags? (Confirm)",
                column:"W",
                field: "SimpleInput",
            },
            {  
                label:"Story Maturity? (Confirm)",
                propertyName:"Story Maturity? (Confirm)",
                column:"X",
                field: "SimpleInput"
            },
            {  
                label:"Assessment Notes",
                propertyName:"Assessment Notes",
                column:"Y",
                field: "FreeText"
            },
            {  
                label:"Would you recommend this story?",
                propertyName:"Would you recommend this story?",
                column:"Z",
                field: "DropDown",
                fieldOptions : ["I would definitely recommend this story","I would recommend this story","I would not recommend this story","I would definitely not recommend this story"]
            },
            {  
                label:"Possible Winner?",
                propertyName:"Possible Winner?",
                column:"AA",
                field: "SwitchInput",
                notrequired: true 
            },
            {  
                label:"Contendor for Best Characters?",
                propertyName:"Contendor for Best Characters?",
                column:"AB",
                field: "SwitchInput",
                notrequired: true 
            },
            {  
                label:"Contendor for Most Engaging World?",
                propertyName:"Contendor for Most Engaging World?",
                column:"AC",
                field: "SwitchInput",
                notrequired: true 
            },
            {  
                label:"Contendor for Biggest Twist?",
                propertyName:"Contendor for Biggest Twist?",
                column:"AD",
                field: "SwitchInput",
                notrequired: true 
            },
            {  
                label:"Contendor for Catchiest Hook?",
                propertyName:"Contendor for Catchiest Hook?",
                column:"AE",
                field: "SwitchInput",
                notrequired: true 
            },
            {  
                label:"Possible Grand Prize Winner?",
                propertyName:"Possible Grand Prize Winner?",
                column:"AF",
                field: "SwitchInput",
                notrequired: true 
            },
            {  label:
                "Judging Complete?",
            propertyName:"Judging Complete?",
                column: "AH"
            },
        ]
    },
    {
        name:"common", 
        columns:[
        { 
        propertyName:"story_id",
        column: "A"
        },
        { 
            propertyName:"title",
                column: "B"
        },
        { 
            propertyName:"story link",
                column: "C"
        },
        { 
            propertyName:"user_id",
            column: "D"
        },
        { 
            propertyName:"user_name",
            column: "E"
        },
        { 
            propertyName:"award_category",
    column: "F"
    },
    { 
        propertyName:"subgenre",
    column: "G"
    },
    { 
        propertyName:"logline",
    column: "H"
    },
    { 
        propertyName:"plot_summary",
    column: "I"
    },
    { 
        propertyName:"target_audience",
    column: "J"
    },
    { 
        propertyName:"mature_themes",
    column: "K"
    },
    { 
        propertyName:"sexual_content",
    column: "L"
    },
    { 
        propertyName:"date_submitted",
        column: "M"
    }
    ]}], 
}
export { wattysConf };																