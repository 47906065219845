import MLab from 'mlab-data-api';

const ApiService = {
    key:null,
    database:null,
    init(key, database) {
        this.key = key;
        this.database = database;
    },
    connect()
    {
        return MLab({
            key:this.key,
            database:this.database,
            host:'https://wpambassadorsapi.herokuapp.com', 
            uri : '/api',
            version :'1',
            timeout : 10000,
        })
    },
    count(collection, query)
    {
        return this.connect().listDocuments({collection:collection,query:query,count:true})
    },
    list(collection, query, order,limit)
    {
        return this.connect().listDocuments({collection:collection,query:query,sort:order,limit:limit})
    },
    findOne(collection,query,order)
    {
        return this.connect().listDocuments({collection:collection,query:query,sort:order,findOne:true})
    },
    get(collection, id)
    {
        return this.connect().viewDocument({collection:collection,id:id})
    },
    delete(collection, id)
    {
        return this.connect().deleteDocument({collection:collection,id:id})
    },
    add(collection,doc)
    {
        return this.connect().insertDocuments({collection:collection,data:[doc]})
    },
    update(collection, query ,doc)
    {
        return this.connect().updateDocuments({collection:collection,data:doc,query:query,all:true})
    }
}

export default ApiService