<script>
import {
  mdbCol,
  mdbRow,
  mdbCard,
  mdbCardBody,
  mdbCardImage,
  mdbInput,
  mdbBtn,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbView,
  mdbMask,
  mdbContainer,
} from "mdbvue";
import { userService } from "../services/user.service.js";
import { errorService } from "../services/error.service.js";
import StoryService from "../services/story.service";
import KeywordsService from "../services/keywords.service";
import _ from "underscore";
import AOS from "aos";
import { langConf } from "@/conf/language.conf.js";
import StoryFlag from "./storyFlag.vue";
import BookmarkButton from "./BookmarkButton.vue";
import moment from "moment";

export default {
  name: "Hotlist",
  components: {
    mdbCol,
    mdbRow,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbCardImage,
    mdbView,
    mdbMask,
    mdbContainer,
    StoryFlag,
    BookmarkButton,
  },
  created() {
    this.loadRouteParams();
    this.user = userService.getUser();
  },
  methods: {
    loadRouteParams() {
      this.stories = [];
      this.offset = 0;
      this.stop = false;
      this.error = null;
      this.storiesCount = 0;
      if (this.$route.params.lang)
        this.selectedLang = _.findWhere(langConf, {
          code: this.$route.params.lang,
        });
      else
        this.selectedLang = localStorage.lang
          ? JSON.parse(localStorage.lang)
          : null;
      this.keywordString = this.$route.params.keywords;
      this.getStories();
      this.spreadSheetLink = KeywordsService.spreadSheet;
    },
    selectLang(lang) {
      this.selectedLang = lang;
      localStorage.lang = JSON.stringify(lang);
    },
    roleIs(role) {
      return this.user.role.indexOf(role) > -1;
    },
    async getStories() {
      if(!this.selectLang&&!this.keywordString)
        return this.stories;
      this.busy = true;
      let list = await StoryService.getHotlist(
            this.keywordString,
            this.selectedLang.id,
            this.offset,
            this.limit
          ).then(response=>{
            if (!response) throw new Error("No story with this tag");
              (this.storiesCount = response.data.nextUrl
              ? response.data.total
              : this.stories.length);
              this.offset = this.offset + this.scrolldistance
            return response.data.stories;
          }).catch((e) => {
              errorService.add(
                e,
                "Hotlist.Vue 88",
                this.selectedLang,
                "hotlist " + this.keywordString
              );
              throw e;
            });
          this.stories = this.stories.concat(list)
          this.busy = false;
    },
    addLanguageDisclamer() {
      this.$confirm({
        title: "How to ad a new language?",
        message: `Just contact mesdamesduc on Discord :)`,
      });
    },
    shouldBeClean(story) {
      if (story.markerAsCleanBy) return true;
      if (
        story.markeAcapOn &&
        moment(story.markeAcapOn) > moment(story.modifyDate)
      )
        return true;
      if (_.findIndex(story.activities, { activity: "mark-as-clean" }) > -1)
        return true;
      return false;
    },
  },
  watch: {
    $route() {
      this.loadRouteParams();
    },
  },
  filters: {
    listToString(string) {
      return string.toString().replace(",", ", ");
    },
    formatDate(date) {
      return moment(date).format("MM-DD-YYYY");
    },
  },
  data() {
    return {
      limit: 20,
      offset: 0,
      storiesCount: 0,
      stories: [],
      keywords: [],
      busy: false,
      langConf: _.sortBy(langConf, "title"),
      selectedLang: false,
      spreadSheetLink: null,
      keywordString: null,
      scrolldistance: 20,
      stop: false,
      user: false,
    };
  },
};
AOS.init();
</script>
<template>
  <mdb-container>
    <mdb-card class="mb-4">
      <mdb-card-body class="">
        <mdb-input
          class="mt-0 mb-3"
          placeholder="Enter keywords"
          ariaDescribedBy="button-addon2"
          v-model="keywordString"
        >
          <mdb-dropdown slot="prepend">
            <mdb-dropdown-toggle
              color="primary"
              size="md"
              slot="toggle"
              class="z-depth-0"
            >
              <span v-if="selectedLang">{{ selectedLang.title }}</span>
              <span v-else>Language</span>
            </mdb-dropdown-toggle>
            <mdb-dropdown-menu class="displayoverflow">
              <mdb-dropdown-item
                v-on:click="selectLang(lang)"
                v-for="lang in langConf"
                v-bind:key="lang.id"
                >{{ lang.title }}</mdb-dropdown-item
              >
              <div class="dropdown-divider"></div>
              <mdb-dropdown-item
                ><a target="_blank" :href="spreadSheetLink"
                  >Fill the keywords sheet</a
                ></mdb-dropdown-item
              >
              <mdb-dropdown-item
                ><a v-on:click="addLanguageDisclamer()"
                  >Ask a new language</a
                ></mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>

          <mdb-btn
            v-if="selectedLang"
            color="secondary"
            tag="a"
            :href="'/hotlist/' + selectedLang.code + '/' + keywordString"
            size="md"
            group
            slot="append"
            id="button-addon2"
            >Go!</mdb-btn
          >
          <bookmark-button
            v-if="selectedLang && !roleIs('none') && !roleIs('other-team')"
            :bookmark="'hotlist/' + selectedLang.code + '/' + keywordString"
            type="list"
            group
            slot="append"
          />
        </mdb-input>
      </mdb-card-body>
    </mdb-card>
    <mdb-container>
      <mdb-row class="mb-3">
        <mdb-col md="4">
          <p>Stories count : {{ storiesCount }}</p>
        </mdb-col>
      </mdb-row>
      <mdb-row
        v-infinite-scroll="getStories"
        infinite-scroll-disabled="busy"
        infinite-scroll-distance="10"
      >
        <mdb-col
          md="3"
          sm="6"
          v-for="(story, index) in stories"
          v-bind:index="index"
          v-bind:key="story.id"
        >
          <mdb-card reverse>
            <mdb-view hover cascade>
              <a :href="'/story/' + story.id" target="_blank">
                <span
                  flex-center
                  style="position: absolute; margin: 5px; z-index: 1"
                  class="badge bg-primary"
                  >#{{ index + 1 }}</span
                >
                <mdb-card-image
                  flex-center
                  :src="story.cover"
                  alt="Card image cap"
                ></mdb-card-image>
                <mdb-mask
                  flex-center
                  waves
                  overlay="white-slight"
                  :class="shouldBeClean(story) ? 'clean' : ''"
                ></mdb-mask>
              </a>
            </mdb-view>
            <mdb-card-body class="text-center" cascade>
              <h6>{{ story.title }}</h6>
              <story-flag :story="story"></story-flag>
              <p class="font-weight-normal" style="font-size: 0.7em">
                <strong>Last update : </strong
                >{{ story.modifyDate | formatDate }}
              </p>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <div v-if="busy" class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="error" class="d-flex justify-content-center">
      <p>{{ error }}</p>
    </div>
  </mdb-container>
</template>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}
.masonry-with-columns div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00997b;
  color: white;
  margin: 0.5rem;
  font-weight: 900;
  font-size: 2rem;
}
.col-md-3 {
  margin-bottom: 1em;
}
.mask.clean {
  opacity: 0.8;
  background: white;
}
.displayoverflow {
  overflow: auto;
  max-height: 300px;
}
</style>
