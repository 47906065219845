import axios from 'axios'

const WattpadV3Service = {
    baseURL:'',
    init(baseURL) {
        this.baseURL = baseURL;
    },
    get(resource) {
        var option = {
            "headers":{
                "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
                "Content-Type": "application/json"
            }
        };
        return axios.get(`${this.baseURL}${resource}`, option)
    },
    post(resource, option)
    {
        return axios.post(`${this.baseURL}${resource}`, option,{headers:{'Content-Type':"application/x-www-form-urlencoded", 'set-cookie':"token:"+process.env.VUE_APP_WP_TOKEN}})
    },
    customRequest(data) {
        return axios(data)
    },
}

export default WattpadV3Service