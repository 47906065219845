<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  components: {
    VueSlider,
  },
  props: {
    title: String,
    propertyName: String,
    initVal: Number,
    options: {
      type: Array,
      default: () => ["/", "Bad", "Average", "Good", "Very good"],
    },
  },
  data() {
    return {
      value: 0,
    };
  },
  created() {
    this.value = this.label[this.initVal];
  },
  methods: {
    change() {
      this.$emit("change", this.options.indexOf(this.value), this.propertyName);
    },
  },
};
</script>

<template>
  <div class="w-100">
    <vue-slider
      class=""
      v-model="value"
      :adsorb="true"
      :data="options"
      :marks="true"
      @change="change"
    ></vue-slider>
  </div>
</template>