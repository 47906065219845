<template>
  <div class="hello">
      <mdb-card class="mb-4">
    <mdb-card-body class="">
    <div v-html="htmldocumentation"></div>
    </mdb-card-body></mdb-card>
  </div>
</template>

<script>
import axios from 'axios'
 import { mdbCard, mdbCardBody } from 'mdbvue';

export default {
  name: 'Documentation',
components: {
        mdbCard,
        mdbCardBody
  },
  data () {
    return {
      htmldocumentation:"",
    };
  },
  created() {
    axios.get('https://docs.google.com/document/d/e/2PACX-1vSfP3QmQkMXq0Vz9Udseh5ucuW8pp4itQ3AgffgWi4iIawR2jderurxYPfiZRGVZ5r4FmmZ7waH_pkd/pub').then(response=>{
      this.htmldocumentation =response.data.match(/<div id="contents"...*<\/div>/)[0].replace("max-width:451.3pt","max-width:95%");
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}
.c39{max-width:100%!important;}
</style>
