import ApiService from "./api.service";
import { userService } from "./user.service";

export const errorService = {
  findOne: function(t, e) {
    return ApiService.findOne("errors", t, e).then(function(t) {
      return t.data;
    });
  },
  list: function(t, e, r) {
    return ApiService.list("errors", t, e, r).then(function(t) {
      return t.data;
    });
  },
  count: function(t) {
    return ApiService.count("errors", t).then(function(t) {
      return t.data;
    });
  },
  add: function (t, e, r, a) {
    var s = {
      user:userService.getUser().username,
      date: new Date().getTime(),
      humanDate: new Date().toString(),
      message: t.message,
      error: t,
      where: e,
      lang: r,
      story: a,
      role: userService.getUser().role,
      browser: navigator.userAgent
    };
    return ApiService.add("errors", s);
  }
};
