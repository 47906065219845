<script>
import StoryService from "../../services/story.service";

import {} from "mdbvue";

export default {
  components: {},
  props: {
    part: Object,
  },
  data() {
    return {
      thepart: false,
      display: true,
    };
  },
  methods: {
    async loadContent() {
      this.thepart = await StoryService.getStoryPartContent(this.part, false);
    },
  },
};
</script>
<template>
  <div class="w-100">
    <div class="d-flex w-100 justify-content-between">
      <span>Part {{ part.partNum }}</span>
      <a @click="loadContent()" v-if="!thepart"> READ IT </a>
      <a @click="display = false" v-if="thepart && display">HIDE</a>
      <a @click="display = true" v-if="thepart && !display">SHOW</a>
    </div>
    <div v-if="thepart && display" class="text-justify part">
      <h4>{{ part.title }}</h4>
      <div v-html="thepart.content"></div>
    </div>
  </div>
</template>
<style >
.part p img {
  max-width: 100%;
}
</style>