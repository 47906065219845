import StoryService from "../services/story.service";
import GoogleSpreadsheetService from "./spreadsheet.service";
import { wattysConf } from "../conf/wattys.conf";
import _ from "underscore";

const WattysService = {
    sheet: {},
    doc: {},
    rows: {},
    filteredRows: [],
    award_categories: wattysConf.award_categories,
    sheetConfig:{},
    async init(spreadSheetId, sheetIndex) {
      console.log("Stat init gspreadsheet");
        this.filteredRows = [];
        this.doc = await GoogleSpreadsheetService.init(spreadSheetId);
        this.sheet = this.doc.sheetsByIndex[
            sheetIndex
        ];
        
      console.log("End init gspreadsheet");
        this.sheetConfig = wattysConf.steps.find(i=>i.index==sheetIndex);
        let commonsCols = wattysConf.steps.find(i=>i.name=="common").columns;
        this.sheetConfig.columns = commonsCols.concat(this.sheetConfig.columns);
        console.log("Stat get sheet rows");
        this.rows = await this.sheet.getRows();
        console.log("End get sheet rows");
        this.rows = this.rows.filter(i => i.story_id != "" &&  i.story_id != "#N/A");
        console.log("Start load cells");
        await this.sheet.loadCells(this.sheetConfig.range);
        console.log("End load cells");
    },
    async setData(id, propertyName, value, markAsDone = false) {
        let index = this.rows.findIndex((item) => item.story_id == id);
        this.rows[index][propertyName] = value;
        let colConf = this.sheetConfig.columns.find(item => item.propertyName == propertyName)
        if (colConf) {
            let celid = colConf.column + (index + 2);
            let cell = this.sheet.getCellByA1(celid);
            cell.value = value;
            this.updateFilteredData(id, propertyName, value);
            if (markAsDone) 
                this.markAsDone(id)
            await this.sheet.saveUpdatedCells();
        } else throw new Error("column head is missing")
    },
    markAsDone(id) {
        let findex = this.filteredRows.findIndex((item) => item.story_id == id);
        this.filteredRows[findex].done = true;
        this.filteredRows[
            findex
        ]["Judging Complete?"] = "yes";
        this.rows[
            this.rows.findIndex((item) => item.story_id == id)
        ]["Judging Complete?"] = "yes";
    },
    updateFilteredData(id, propertyName, value)
    {
        let index = this.filteredRows.findIndex(
            (item) => item.story_id == id
         );
        if (index > -1) this.filteredRows[index][propertyName] = value;
        return this.filteredRows;
  },
  async filterCount(filters) {
    let filteredRows = this.rows
    for (let f in filters) {
        let filter = filters[f];
        filteredRows = filteredRows.filter(
            (item) => {
                return item[filter.key] == filter.value && item["Judging Complete?"] != "Yes"
            }
              
        )
    }
    return filteredRows.length;
    },
  async filter(filters, offset, limit) {
      console.log(offset, limit)
        let filteredRows = this.rows
        for (let f in filters) {
            let filter = filters[f];
            filteredRows = filteredRows.filter(
                (item) => {
                    return item[filter.key] == filter.value && item["Judging Complete?"] != "Yes"
                }
                  
            )
    }
      //sort by date
      filteredRows = filteredRows.sort(function (a, b) {
        return new Date(a.date_submitted) - new Date(b.date_submitted);
      });
    console.log("End filtering by submission date")
    let result = [];
      if ((offset==0 || offset) && limit)
        filteredRows = filteredRows.slice(offset, offset+limit);
        console.log("Range : "+filteredRows.length);
          for (let s in filteredRows) {
              let row = filteredRows[s];
              row.done = (row["Judging Complete?"] == "Yes"||row["Judging Complete?"] == "Pending") ? true : false;
              if (!row.done) {
                let wpStory = await StoryService.getStory(row.story_id).catch(
                    async (e) => {
                      if (
                        e.response &&
                        e.response.data &&
                        e.response.data.error_type &&
                        e.response.data.error_type == "NotFound"
                      ) {
                          await this.setData(row.story_id, this.sheetConfig.validationKey, "N/A");
                          await this.setData(row.story_id, this.sheetConfig.notesKey, "This story is not found");
                      }
                    }
                    );
                    if (wpStory) {
                        row.key = row.story_id;
                        row.alerts = []
                        let push =true;
                        if (new Date(wpStory.createDate) < new Date("01/01/2020")){
                          await this.setData(row.story_id, this.sheetConfig.validationKey, "N/A");
                          await this.setData(row.story_id, this.sheetConfig.notesKey, `${row.title} - publication date before 2020`);
                          push = false
                        }
                        if(push) {
                          let scoreValues = this.sheetConfig.columns.filter(i=>i.field == "Slider").map(i=> {
                              row[i.propertyName] = (row[i.propertyName]=="")?0:Number(row[i.propertyName])
                              return row[i.propertyName]
                            }).filter(i=>i);
                          row.score = (scoreValues.length > 0) ? scoreValues.reduce((sum, x) => sum + x) : 0;
                          this.sheetConfig.columns.filter(i=>i.field == "SwitchInput").map(i=> {
                            row[i.propertyName] = (row[i.propertyName]=="FALSE")?false:true
                          });
                          if (!wpStory.mature && row.mature_themes != "none" && row.sexual_content != "no_sexual_content")
                          row.alerts.push("According to author submission, this story should be mature and is not");
                        if (wpStory.activities.find(a=>a.activity.indexOf("report")>-1))
                            row.alerts.push("This story has been reported to divers");
                          this.filteredRows.push({ ...row, wpStory }); 
                          result.push({ ...row, wpStory });
                        }
                    }
              }
              
        }
        console.log("End getting awaiting rows")
        return result;
      },
      getCategoryStatistics() { 
        let stats = {};
        wattysConf.award_categories.map(a=>{
          let filtered  = this.rows.filter(
            (item) => {
              if((item["Confirm Category"] == a))
                return true
              return (item["award_category"] == a)
            }
            );
          stats[a] = { 
            total: filtered.length, 
            'to the next list' : filtered.filter((item) => item[this.sheetConfig.validationKey] == "Yes").length,
            done : filtered.filter(i=>i["Judging Complete?"]=="Yes").length, 
            left :filtered.filter(i=>i["Judging Complete?"]=="No").length
          }
        })
        return stats;
      },
    getStatistics(filters, title) {
        let rows = this.rows;
        for (let f in filters) {
            let filter = filters[f];
            rows = rows.filter(
                (item) => { return item[filter.key] == filter.value }
                );
        }
        let stats = {
            title: title,
            progress: Math.round(
              (rows.filter((item) => item["Judging Complete?"] == "Yes")
                .length /
                rows.length) *
                100
            ),
            data: [
              {
                name: "Total",
                value: rows.length,
              },
              {
                name: "Done",
                value: rows.filter(
                  (item) => item["Judging Complete?"] == "Yes"
                ).length,
              },
              {
                name: "Left",
                value: rows.filter(
                  (item) => item["Judging Complete?"] == "No"
                ).length,
              },
              {
                name: "Yes",
                value: rows.filter((item) => item[this.sheetConfig.validationKey] == "Yes").length,
              },
              {
                name: "No",
                value:rows.filter((item) => item[this.sheetConfig.validationKey] == "No").length,
              },
              {
                name: "N/A (including not found stories)",
                value:rows.filter((item) => item[this.sheetConfig.validationKey] == "N/A").length,
              },
            ],
        }
        if (title.indexOf("team") > -1) {
            stats.classment = [];
            let classment = _.groupBy(this.rows, "Assigned Judge")
            for (let c in classment)
            {
                stats.classment.push({
                    judge:c,
                    doneCount:this.rows.filter(
                        (item) => item["Judging Complete?"] == "Yes" && item["Assigned Judge"] == c
                    ).length,
                    rows: stats.classment[c]
                })
            }
            stats.classment = stats.classment.filter(i => i.judge !="")
            stats.classment = stats.classment.sort((a, b) => b.doneCount - a.doneCount)
        }
       //O console.log(stats)
        // console.log(` ${stats.title} :
        // total : ${stats.data[0].value}
        // done :  ${stats.data[1].value}
        // left :  ${stats.data[2].value}
        // yes :  ${stats.data[3].value}
        // no :  ${stats.data[4].value}
        // N/A :  ${stats.data[5].value}
        // PROGRESS : ${stats.progress}%`)
        return  stats
      }
};

export default WattysService;
