import ApiService from "./api.service";
import { userService } from "./user.service";
import _ from "underscore";

export const reportFields = {
  reasonToReport: {
      id: "reasonToReport",
      label: "Reason for removal OR why it needs a mature rating, AND where to find evidence",
      description: 'Please be as clear as possible. Examples: "cover has porn content" "collection of sex gifs" "banned media in ch5, halfway into the chapter" "collection of sex oneshots, seems to be pure porn" "ch3 halfway in has detailed description of self-harm" "detailed suicide in ch12, almost at the end" "profile pic shows selfharm" "User states to be <13 in their profile bio"',
      extFormEntry: "2002575298",
      value: "",
      icon: "pencil-alt"
  },
  linksToPublishedContent: {
      id: "linksToPublishedContent",
      label: "Link to Published work",
      description: "Pref. Amazon/Google books link. We need to be able to compare the Wattpad story to the alleged original story. (So make sure the link to the original has a preview of the work. No preview means we can't compare :( )",
      extFormEntry: "2006285719",
      value: "",
      icon: "link"
  },
  anyComments: {
      id: "anyComments",
      label: "Additional notes",
      extFormEntry: "1958476459",
      value: "",
      icon: "pencil-alt"
  }
};
export const actitiviesService = {
  default: {
    icon: "exclamation-triangle",
    color: "gray"
  },
  actionsList: {
    "report-cover-to-wattpad": {
      id: "report-cover-to-wattpad",
      icon: "file-image",
      label: "Report this cover",
      color: "lime",
      allowedRoles: ["banned-diver", "full-diver"],
      link: "https://www.wattpad.com/help/content?&media_link={COVER}&story_link=https%3A%2F%2Fwww.wattpad.com%2Fstory%2F{STORY_ID}&message=this+media+is+inapropriate"
  },
  "report-cover-to-diver": {
      id: "report-cover-to-diver",
      icon: "file-image",
      label: "Report this cover",
      color: "lime",
      allowedRoles: ["content", "other-team", "copyright-diver"],
      diverAssignement: ["full-diver", "banned-diver"],
      link: "https://docs.google.com/forms/d/e/1FAIpQLScfyGipkqVUo1JvstrtovgvhHLRS8i32D2Ze_rT8WeG8CEWcw/viewform?c=0&w=1&entry.1757867264=https://www.wattpad.com/story/{STORY_ID}&entry.1829207438={USERNAME}&entry.1760070354={LANG}&entry.1661423180=Other&entry.2002575298=Banned+cover"
  },
  "report-banned-self-harm": {
      id: "report-banned-self-harm",
      label: "Banned (Self Harm)",
      icon: "hammer",
      color: "light-green",
      allowedRoles: ["content", "other-team", "copyright-diver"],
      formfileds: [reportFields.reasonToReport],
      diverAssignement: ["full-diver", "banned-diver"],
      link: "https://docs.google.com/forms/d/e/1FAIpQLScfyGipkqVUo1JvstrtovgvhHLRS8i32D2Ze_rT8WeG8CEWcw/viewform?c=0&w=1&entry.1757867264=https://www.wattpad.com/story/{STORY_ID}&entry.1829207438={USERNAME}&entry.1760070354={LANG}&entry.1661423180=Self harm"
  },
  "report-banned": {
      id: "report-banned",
      label: "Banned (Porn)",
      icon: "hammer",
      color: "light-green",
      allowedRoles: [],
      formfileds: [reportFields.reasonToReport],
      diverAssignement: ["full-diver", "banned-diver"],
      link: "https://docs.google.com/forms/d/e/1FAIpQLScfyGipkqVUo1JvstrtovgvhHLRS8i32D2Ze_rT8WeG8CEWcw/viewform?c=0&w=1&entry.1757867264=https://www.wattpad.com/story/{STORY_ID}&entry.1829207438={USERNAME}&entry.1760070354={LANG}&entry.1661423180=Sexual"
  },
  "report-banned-porn": {
      id: "report-banned-porn",
      label: "Banned (Porn)",
      icon: "hammer",
      color: "light-green",
      allowedRoles: ["content", "other-team", "copyright-diver"],
      formfileds: [reportFields.reasonToReport],
      diverAssignement: ["full-diver", "banned-diver"],
      link: "https://docs.google.com/forms/d/e/1FAIpQLScfyGipkqVUo1JvstrtovgvhHLRS8i32D2Ze_rT8WeG8CEWcw/viewform?c=0&w=1&entry.1757867264=https://www.wattpad.com/story/{STORY_ID}&entry.1829207438={USERNAME}&entry.1760070354={LANG}&entry.1661423180=Sexual"
  },
  "report-rating": {
      id: "report-rating",
      icon: "random",
      label: "Rating",
      color: "light-green",
      allowedRoles: ["content", "other-team", "copyright-diver"],
      formfileds: [reportFields.reasonToReport],
      diverAssignement: ["full-diver", "banned-diver"],
      link: "https://docs.google.com/forms/d/e/1FAIpQLScfyGipkqVUo1JvstrtovgvhHLRS8i32D2Ze_rT8WeG8CEWcw/viewform?c=0&w=1&entry.1757867264=https://www.wattpad.com/story/{STORY_ID}&entry.1829207438={USERNAME}&entry.1760070354={LANG}&entry.1661423180=Sexual"
  },
  "report-copyright": {
      id: "report-copyright",
      icon: "copyright",
      label: "copyright",
      color: "light-green",
      allowedRoles: ["other-team", "banned-diver", "content"],
      formfileds: [reportFields.linksToPublishedContent, reportFields.anyComments],
      diverAssignement: ["full-diver", "copyright-diver"],
      link: "https://docs.google.com/forms/d/e/1FAIpQLScrJ28Yj8zwDW47GDnEXwMzILJlRtbS-Hrn0KGphhFSK9l2uA/viewform?c=0&w=1&entry.1360737325=https://www.wattpad.com/story/{STORY_ID}&entry.1617899760={USERNAME}&entry.2053117725={LANG}"
  },
  "report-CESM": {
      id: "report-CESM",
      icon: "child",
      label: "CESM",
      color: "amber",
      allowedRoles: ["other-team", "content"],
      link: "/#"
  },
  "delete-banned": {
      id: "delete-banned",
      icon: "hammer",
      label: "Hammer it!",
      color: "",
      delete: !0,
      allowedRoles: ["banned-diver", "full-diver"],
      link: "https://www.wattpad.com/moderator/contentreview?groupid={STORY_ID}"
  },
  "delete-copyright": {
      id: "delete-copyright",
      icon: "copyright",
      label: "Hammer it!",
      color: "",
      delete: !0,
      allowedRoles: ["copyright-diver", "full-diver"],
      link: "https://www.wattpad.com/moderator/contentreview?groupid={STORY_ID}"
  },
  "change-rating": {
      id: "change-rating",
      icon: "random",
      label: "Change Rating",
      color: "",
      allowedRoles: ["banned-diver", "full-diver"],
      link: "https://www.wattpad.com/moderator/contentreview?groupid={STORY_ID}"
  },
  "ts-report-cesm": {
      id: "ts-report-cesm",
      icon: "child",
      label: "CESM",
      color: "amber",
      allowedRoles: ["banned-diver", "full-diver", "copyright-diver"],
      link: "https://www.wattpad.com/help/content?message=CESM+WARNING&story_link=https%3A%2F%2Fwww.wattpad.com%2Fstory%2F{STORY_ID}"
  },
  "ts-reported": {
      id: "ts-reported",
      icon: "arrow-circle-up",
      label: "Mark as reported to TS",
      color: "amber",
      allowedRoles: ["banned-diver", "full-diver", "copyright-diver"]
  },
  "mark-as-unclean": {
      id: "mark-as-unclean",
      icon: "eraser",
      label: "Mark as unclean",
      color: "grey",
      allowedRoles: ["content", "banned-diver", "full-diver", "copyright-diver"]
  },
  "mark-as-clean": {
      id: "mark-as-clean",
      icon: "smile",
      label: "Mark as clean",
      tooltip: "Mark this story as reviewed and clean",
      color: "green",
      allowedRoles: ["content", "banned-diver", "full-diver", "copyright-diver"]
  },
  "mark-as-clean-as-possible": {
      id: "mark-as-clean-as-possible",
      icon: "smile",
      label: "Mark as clean as possible",
      tooltip: "Use this button to inform other ambs you checked this story and it as clean as possible.",
      color: "green",
      allowedRoles: ["content", "banned-diver", "full-diver", "copyright-diver"]
  },
  "user-banned-explicit": {
      id: "user-banned-explicit",
      icon: "trash",
      label: "Delete user for banned",
      color: "red",
      hiddenActivity: !0,
      allowedRoles: ["banned-diver", "full-diver", "copyright-diver"]
  },
  "user-banned-copyright": {
      id: "user-banned-copyright",
      icon: "trash",
      label: "Delete user for copyright",
      color: "red",
      hiddenActivity: !0,
      allowedRoles: ["banned-diver", "full-diver", "copyright-diver"]
  }
  },
  findOne(query, order) {
    return ApiService.findOne("activites", query, order).then(response => {
      return response.data;
    });
  },
  list(query, order, limit) {
    return ApiService.list("activites", query, order, limit).then(response => {
      return response.data;
    });
  },
  listAndFormat(query, order, limit) {
    return this.list(query, order, limit).then(activities => {
      for (let a in activities) {
        let activity = activities[a];
        activities[a] = { ...activities[a], 
          content: activity.user + " - " + activity.activity.split("-").join(" "),
          icon:this.actionsList[activity.activity]
          ? this.actionsList[activity.activity].icon
            : this.default.icon,
          color : this.actionsList[activity.activity]
            ? this.actionsList[activity.activity].color
            : this.default.color
        }
        if (activity.reasonToReport) {
          let details = activity.reasonToReport.split(" | ");
          activities[a].details = "";
          for (let d in details) {
            activities[a].details = activities[a].details + "<br />";
            let urlRegex = /(https?:\/\/[^ ]*)/;
            activities[a].details =
            activities[a].details +
              details[d].replace(
                urlRegex,
                `<a href="$1" target="_blank">see link</a>`
              );
          }
        }
      }
      return activities;
    })
  },
  count(query) {
    return ApiService.count("activites", query).then(response => {
      return response.data;
    });
  },
  update(query, docs) {
    return ApiService.update("activites", query, docs).then(response => {
      return response.data;
    });
  },
  add(activity) {
    var newActivity = _.extend(
      {
        user: userService.getUser().username,
        date: (new Date).getTime()
      },
      activity
    );
    return ApiService.update(
      "activites",
      { storyId: activity.storyId.toString() },
      { expired: true }
    ).then(() => {
      return ApiService.add("activites", newActivity).then(() => {
        return newActivity;
      });
    });
  }
};
