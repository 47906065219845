import axios from 'axios'

const WattpadV4Service = {
    baseURL:'',
    init(baseURL) {
        this.baseURL = baseURL;
    },
    get(resource) {
        var option = {
            "headers":{
                "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
                "Content-Type": "application/json"
            }
        };
        return axios.get(`${this.baseURL}${resource}`,option)
    },
    customRequest(data) {
        return axios(data)
    },
}

export default WattpadV4Service